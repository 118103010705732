import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CheckCircle,
  Pencil,
  Question,
  YoutubeLogo,
  CaretLeft,
  CaretRight,
} from 'phosphor-react';
import {
  DashboardLayout,
  DashboardNavbar,
  Footer,
} from 'features/default-layout';
import Notification from 'components/Notification';
import Loading from 'components/Loading';
import Card from '@mui/material/Card';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import DataTable from '../components/DataTable';
import { connect } from 'react-redux';
import {
  deniedQuestionsPageStart,
  getDeniedQuestionsAction,
} from 'redux/actions/pages';
import { LanguageConstants } from 'constants';
import CustomTooltipWhiteSpace from 'components/CustomTooltipWhiteSpace';
import { RouteConstants } from 'constants';
import { QuestionStateTypes } from 'enumerations';
import ArgonPagination from 'components/ArgonPagination';
import FilterCard from '../components/FilterCard';

const DeniedQuestions = ({
  questions,
  deniedQuestionsPageStart,
  getDeniedQuestionsAction,
  filter,
}) => {
  const navigate = useNavigate();
  const [tableFilter, setTableFilter] = useState(null);

  const dataTableColumns = [
    { Header: '#', accessor: 'id' },
    { Header: 'kullanım', accessor: 'usage' },
    { Header: 'soru tipi', accessor: 'questionType' },
    { Header: 'zorluk', accessor: 'difficulty' },
    { Header: 'soru', accessor: 'question' },
    { Header: 'çözüm', accessor: 'solution' },
    { Header: 'video', accessor: 'video' },
    { Header: 'hazırlayan', accessor: 'createdBy' },
    { Header: 'tarih', accessor: 'updatedAt' },
    { Header: 'işlemler', accessor: 'actions' },
  ];
  const dataTableData = {
    columns: dataTableColumns,
    rows: questions.map((question) => ({
      id: question.id,
      usage: LanguageConstants[question.usage],
      questionType: LanguageConstants[question.questionType],
      difficulty: question.difficulty,
      question: (
        <ArgonBox ml={-1.325}>
          <CustomTooltipWhiteSpace
            title={`Görseli farklı\nsekmede aç`}
            placement="left"
          >
            <ArgonButton
              variant="contained"
              color="secondary"
              iconOnly
              style={{ padding: 5 }}
              onClick={() => window.open(question.questionUrl, '_blank')}
            >
              <Question size={32} color="#FFFFFF" weight="duotone" />
            </ArgonButton>
          </CustomTooltipWhiteSpace>
        </ArgonBox>
      ),
      solution:
        question.imageAnswerUrl !== null &&
        question.imageAnswerUrl !== undefined &&
        question.imageAnswerUrl !== '' ? (
          <ArgonBox ml={-1.325}>
            <CustomTooltipWhiteSpace
              title={`Görseli farklı\nsekmede aç`}
              placement="top"
            >
              <ArgonButton
                variant="contained"
                color="secondary"
                iconOnly
                style={{ padding: 5 }}
                onClick={() => window.open(question.imageAnswerUrl, '_blank')}
              >
                <CheckCircle size={32} color="#FFFFFF" weight="duotone" />
              </ArgonButton>
            </CustomTooltipWhiteSpace>
          </ArgonBox>
        ) : (
          'NA'
        ),
      video:
        question.videoAnswerUrl !== null &&
        question.videoAnswerUrl !== undefined &&
        question.videoAnswerUrl !== '' ? (
          <ArgonBox ml={-1.325}>
            <CustomTooltipWhiteSpace
              title={`Videoyu farklı\nsekmede aç`}
              placement="right"
            >
              <ArgonButton
                variant="contained"
                color="secondary"
                iconOnly
                style={{ padding: 5 }}
                onClick={() => window.open(question.videoAnswerUrl, '_blank')}
              >
                <YoutubeLogo size={32} color="#FFFFFF" weight="duotone" />
              </ArgonButton>
            </CustomTooltipWhiteSpace>
          </ArgonBox>
        ) : (
          'NA'
        ),
      createdBy: question.createdByName,
      updatedAt: question.updatedAt,
      actions: (
        <ArgonBox ml={-1.325}>
          <CustomTooltipWhiteSpace title={`Düzenle`} placement="left">
            <ArgonButton
              variant="contained"
              color="secondary"
              iconOnly
              style={{ padding: 5 }}
              onClick={() =>
                navigate(RouteConstants.OTHER_EDIT_QUESTION, {
                  state: { question },
                })
              }
            >
              <Pencil size={32} color="#FFFFFF" weight="duotone" />
            </ArgonButton>
          </CustomTooltipWhiteSpace>
        </ArgonBox>
      ),
    })),
  };

  useEffect(() => {
    if (
      filter?.questionState === QuestionStateTypes.denied ||
      filter?.questionState === null
    ) {
      setTableFilter({
        ...filter,
        questionState: QuestionStateTypes.denied,
      });
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    deniedQuestionsPageStart();
    if (filter?.questionState === QuestionStateTypes.denied) {
      getDeniedQuestionsAction({ filter });
    } else {
      getDeniedQuestionsAction({ filter: tableFilter });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Loading />
      <Notification />
      <DashboardLayout>
        <DashboardNavbar />
        <ArgonBox pt={6} pb={3}>
          <ArgonBox>
            <Card>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  Revize Bekleyen Sorular
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox pb={3}>
          <ArgonBox>
            <FilterCard
              filterAction={() =>
                getDeniedQuestionsAction({
                  filter: { ...tableFilter, page: 1 },
                })
              }
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
            />
          </ArgonBox>
        </ArgonBox>
        <ArgonBox pb={3}>
          <ArgonBox mb={3}>
            <Card>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonTypography variant="h6" fontWeight="medium">
                  Sorular
                </ArgonTypography>
              </ArgonBox>
              <DataTable
                isSorted={false}
                showTotalEntries={false}
                table={dataTableData}
              />
              <ArgonBox p={3}>
                <ArgonPagination size="medium">
                  <ArgonPagination
                    item
                    onClick={() => {
                      if (tableFilter?.page > 1) {
                        getDeniedQuestionsAction({
                          filter: {
                            ...tableFilter,
                            page: tableFilter?.page - 1 ?? 1,
                          },
                        });
                      }
                    }}
                  >
                    <CaretLeft color="#333333" weight="bold" />
                  </ArgonPagination>
                  <ArgonPagination item variant="contained">
                    {tableFilter?.page ?? 1}
                  </ArgonPagination>
                  <ArgonPagination item>
                    <CaretRight
                      color="#333333"
                      weight="bold"
                      onClick={() => {
                        if (questions?.length === 50) {
                          getDeniedQuestionsAction({
                            filter: {
                              ...tableFilter,
                              page: tableFilter?.page + 1 ?? 1,
                            },
                          });
                        }
                      }}
                    />
                  </ArgonPagination>
                </ArgonPagination>
              </ArgonBox>
            </Card>
          </ArgonBox>
        </ArgonBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

DeniedQuestions.defaultProps = {
  questions: [],
  filter: null,
};

DeniedQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.any),
  deniedQuestionsPageStart: PropTypes.func.isRequired,
  getDeniedQuestionsAction: PropTypes.func.isRequired,
  filter: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  questions: state.data.deniedQuestions,
  filter: state.ui.filter,
});

const mapDispatchToProps = {
  deniedQuestionsPageStart,
  getDeniedQuestionsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeniedQuestions);
