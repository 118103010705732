/* eslint-disable no-case-declarations */
import { MessageTypes } from 'enumerations';
import { setLoader } from 'redux/actions/ui';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setNotification } from 'redux/actions/notifications';
import {
  GET_TOPICS_ACTION,
  GET_SUBTOPICS_ACTION,
  GET_USERS_ACTION,
} from 'redux/actions/pages';
import { BASE_URL } from 'redux/actions/constants';
import { setData } from 'redux/actions/data';

const GET_TOPICS_URL = `${BASE_URL}/api/topic/get-topics`;
const GET_SUBTOPICS_URL = `${BASE_URL}/api/subtopic/get-subtopics`;
const GET_USERS_URL = `${BASE_URL}/api/user/get-users`;

const GET_TOPICS_ACTION_ERROR_MSG =
  'Konu listesi alınırken hata meydana geldi.';
const GET_SUBTOPICS_ACTION_ERROR_MSG =
  'Alt konu listesi alınırken hata meydana geldi.';
const GET_USERS_ACTION_ERROR_MSG =
  'Kullanıcı listesi alınırken hata meydana geldi.';

export const commonMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { data } = getState();

    switch (action.type) {
      case GET_TOPICS_ACTION:
        if (data.topics?.length > 0) {
          // Do nothing
        } else {
          next(
            setLoader({
              state: true,
              feature: GET_TOPICS_ACTION,
              identifier: GET_TOPICS_ACTION,
            })
          );
          next(
            apiRequest({
              method: 'GET',
              url: GET_TOPICS_URL,
              feature: GET_TOPICS_ACTION,
            })
          );
        }

        break;
      case `${GET_TOPICS_ACTION} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: GET_TOPICS_ACTION,
            identifier: GET_TOPICS_ACTION,
          })
        );

        next(
          setData({
            data: action.payload,
            feature: GET_TOPICS_ACTION,
          })
        );

        break;
      case `${GET_TOPICS_ACTION} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: GET_TOPICS_ACTION,
            identifier: GET_TOPICS_ACTION,
          })
        );
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_TOPICS_ACTION_ERROR_MSG,
            feature: GET_TOPICS_ACTION,
          })
        );
        break;
      case GET_SUBTOPICS_ACTION:
        if (data.subtopics?.length > 0) {
          // Do nothing
        } else {
          next(
            setLoader({
              state: true,
              feature: GET_SUBTOPICS_ACTION,
              identifier: GET_SUBTOPICS_ACTION,
            })
          );
          next(
            apiRequest({
              method: 'GET',
              url: GET_SUBTOPICS_URL,
              feature: GET_SUBTOPICS_ACTION,
            })
          );
        }
        break;
      case `${GET_SUBTOPICS_ACTION} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: GET_SUBTOPICS_ACTION,
            identifier: GET_SUBTOPICS_ACTION,
          })
        );

        next(
          setData({
            data: action.payload,
            feature: GET_SUBTOPICS_ACTION,
          })
        );

        break;
      case `${GET_SUBTOPICS_ACTION} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: GET_SUBTOPICS_ACTION,
            identifier: GET_SUBTOPICS_ACTION,
          })
        );
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_SUBTOPICS_ACTION_ERROR_MSG,
            feature: GET_SUBTOPICS_ACTION,
          })
        );
        break;
      case GET_USERS_ACTION:
        if (data.users?.length > 0) {
          // Do nothing
        } else {
          next(
            setLoader({
              state: true,
              feature: GET_USERS_ACTION,
              identifier: GET_USERS_ACTION,
            })
          );
          next(
            apiRequest({
              method: 'GET',
              url: GET_USERS_URL,
              feature: GET_USERS_ACTION,
            })
          );
        }
        break;
      case `${GET_USERS_ACTION} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: GET_USERS_ACTION,
            identifier: GET_USERS_ACTION,
          })
        );

        next(
          setData({
            data: action.payload,
            feature: GET_USERS_ACTION,
          })
        );

        break;
      case `${GET_USERS_ACTION} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: GET_USERS_ACTION,
            identifier: GET_USERS_ACTION,
          })
        );
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_USERS_ACTION_ERROR_MSG,
            feature: GET_USERS_ACTION,
          })
        );
        break;
      default:
        break;
    }
  };
