/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonInput from 'components/ArgonInput';
import { ErrorMessage, Field } from 'formik';
import ArgonSelect from 'components/ArgonSelect';

function FormField({ label, name, type, ...rest }) {
  if (type === 'select') {
    const { setFieldValue, setFieldTouched, resets, ...others } = rest;
    return (
      <>
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </ArgonTypography>
        </ArgonBox>
        <Field
          {...others}
          menuPortalTarget={document.body}
          name={name}
          onChange={(val) => {
            if (resets && resets.length > 0) {
              resets.forEach((r) => {
                setFieldValue(r.name, r.value);
                setFieldTouched(r.name, false);
              });
            }
            setFieldValue(name, val);
          }}
          onBlur={() => setFieldTouched(name, true)}
          as={ArgonSelect}
        />
        <ArgonBox mt={0.75}>
          <ArgonTypography component="div" variant="caption" color="error">
            <ErrorMessage name={name} />
          </ArgonTypography>
        </ArgonBox>
      </>
    );
  }
  return (
    <>
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </ArgonTypography>
      </ArgonBox>
      <ArgonInput name={name} {...rest} />
    </>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default FormField;
