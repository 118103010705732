/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI example components
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  DashboardLayout,
  DashboardNavbar,
  Footer,
} from 'features/default-layout';

import QuestionForms from '../components/question-forms';
import { connect } from 'react-redux';
import {
  newQuestionPageStart,
  getTopicsAction,
  getSubtopicsAction,
} from 'redux/actions/pages';
import Notification from 'components/Notification';
import Loading from 'components/Loading';

const NewQuestionPage = ({
  newQuestionPageStart,
  getTopicsAction,
  getSubtopicsAction,
}) => {
  useEffect(() => {
    newQuestionPageStart();
    getTopicsAction();
    getSubtopicsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading />
      <Notification />
      <DashboardLayout>
        <DashboardNavbar />
        <QuestionForms
          title="Yeni Soru Oluştur"
          description="Yeni soru oluşturma ekranı"
        />
        <Footer />
      </DashboardLayout>
    </>
  );
};

NewQuestionPage.defaultProps = {};

NewQuestionPage.propTypes = {
  newQuestionPageStart: PropTypes.func.isRequired,
  getTopicsAction: PropTypes.func.isRequired,
  getSubtopicsAction: PropTypes.func.isRequired,
};

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = {
  newQuestionPageStart,
  getTopicsAction,
  getSubtopicsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewQuestionPage);
