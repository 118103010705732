import axios from 'axios';

import { API_REQUEST, apiSuccess, apiError } from 'redux/actions/api';
import { TOKEN_STORAGE_KEY } from 'redux/actions/constants';

export const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
      const { url, method, feature, token: inputToken } = action.meta;

      let bearerToken = '';

      if (
        inputToken === undefined ||
        inputToken === null ||
        inputToken === ''
      ) {
        const stateToken = getState().auth.token;

        if (
          inputToken === undefined ||
          inputToken === null ||
          inputToken === ''
        ) {
          const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
          bearerToken = storedToken;
        } else {
          bearerToken = stateToken;
        }
      } else {
        bearerToken = inputToken;
      }

      if (
        bearerToken !== undefined &&
        bearerToken !== null &&
        bearerToken !== ''
      ) {
        // eslint-disable-next-line dot-notation
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${bearerToken}`;
      } else {
        // eslint-disable-next-line dot-notation
        delete axios.defaults.headers.common['Authorization'];
      }

      axios[method.toLowerCase()](url, action.payload, {
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.status === 'success') {
            dispatch(apiSuccess({ response: response.data.data, feature }));
          } else if (response.data.status === 'error') {
            dispatch(apiError({ error: response.data.message, feature }));
          } else {
            // eslint-disable-next-line no-console
            console.log('Error occured on response:', response);
            dispatch(apiError({ error: 'Error occured', feature }));
          }
        })
        .catch((error) => {
          dispatch(apiError({ error: error?.response?.data, feature }));
        });
    }
  };
