/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ThumbsUp, HandPalm } from 'phosphor-react';
import { connect } from 'react-redux';
import ArgonAlert from 'components/ArgonAlert';

import './_custom-alert.css';

const Alert = ({ notifications }) => (
  <div className="custom-alert">
    {notifications.map((notification) => (
      <ArgonAlert
        key={notification.message.id}
        color={notification.type === 'success' ? 'info' : 'error'}
      >
        {notification.type === 'success' ? (
          <ThumbsUp size={24} weight="duotone" />
        ) : (
          <HandPalm size={24} weight="duotone" />
        )}
        <strong>
          {notification.type === 'success' ? ' Başarılı! ' : ' Başarısız! '}
        </strong>
        <p>{notification.message.message}</p>
      </ArgonAlert>
    ))}
  </div>
);

Alert.defaultProps = {
  notifications: [],
};

Alert.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps, {})(Alert);
