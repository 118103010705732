/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from 'yup';
import checkout from './questionForm';

const {
  formField: {
    usage,
    topics,
    subtopics,
    questionType,
    difficulty,
    correctAnswer,
  },
} = checkout;

const questionFormValidations = {
  validation: Yup.object().shape({
    [usage.name]: Yup.object().nullable().required(usage.errorMsg),
    [topics.name]: Yup.array()
      .min(1, topics.errorMsg)
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() })),
    [subtopics.name]: Yup.array()
      .min(1, subtopics.errorMsg)
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() })),
    [questionType.name]: Yup.object()
      .nullable()
      .required(questionType.errorMsg),
    [difficulty.name]: Yup.object().nullable().required(difficulty.errorMsg),
    [correctAnswer.name]: Yup.object()
      .nullable()
      .required(correctAnswer.errorMsg),
  }),
};

export default questionFormValidations;
