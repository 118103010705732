import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const SIGNIN_FEATURE = `${PAGES} [Signin]`;

// action types
export const SIGNIN_PAGE_START = `${SIGNIN_FEATURE} PAGE_START`;
export const SIGNIN_ACTION = `${SIGNIN_FEATURE} SIGNIN_ACTION`;

// action creators
export const signinPageStart = () => ({
  type: SIGNIN_PAGE_START,
});

export const signinAction = ({ email, password }) => ({
  type: SIGNIN_ACTION,
  payload: { email, password },
});
