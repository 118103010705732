const CorrectAnswers = Object.freeze({
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
});

const getCorrectAnswerFromString = (value) => {
  switch (value) {
    case 'A':
      return CorrectAnswers.a;
    case 'B':
      return CorrectAnswers.b;
    case 'C':
      return CorrectAnswers.c;
    case 'D':
      return CorrectAnswers.d;
    default:
      return null;
  }
};

export { CorrectAnswers, getCorrectAnswerFromString };
