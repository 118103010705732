const RouteConstants = {
  HOME: '/home',
  SIGNIN: '/sigin',
  NEW_QUESTION: '/own-questions/new-question',
  OWN_EDIT_QUESTION: '/own-questions/edit-question',
  OTHER_EDIT_QUESTION: '/other-questions/edit-question',
  MY_WAITING_APPROVE_QUESTIONS: '/own-questions/my-waiting-approve-questions',
  MY_APPROVED_QUESTIONS: '/own-questions/my-approved-questions',
  MY_DENIED_QUESTIONS: '/own-questions/my-denied-questions',
  WAITING_APPROVE_QUESTIONS: '/other-questions/waiting-approve-questions',
  APPROVED_QUESTIONS: '/other-questions/approved-questions',
  DENIED_QUESTIONS: '/other-questions/denied-questions',
};

export default RouteConstants;
