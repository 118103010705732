/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from './questionForm';

const {
  formField: {
    usage,
    topics,
    subtopics,
    questionType,
    difficulty,
    correctAnswer,
    solutionVideoUrl,
  },
} = checkout;

const questionFormInitialValues = {
  [usage.name]: null,
  [topics.name]: [],
  [subtopics.name]: [],
  [questionType.name]: null,
  [difficulty.name]: null,
  [correctAnswer.name]: null,
  [solutionVideoUrl.name]: '',
};

export default questionFormInitialValues;
