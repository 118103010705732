/* eslint-disable no-case-declarations */
import { MessageTypes } from 'enumerations';
import { setRedirect, setLoader, setOrigin } from 'redux/actions/ui';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setAuth } from 'redux/actions/auth';
import { setNotification } from 'redux/actions/notifications';
import { setToStorage, removeFromStorage } from 'redux/actions/storage';
import {
  SIGNIN_FEATURE,
  SIGNIN_PAGE_START,
  SIGNIN_ACTION,
} from 'redux/actions/pages';
import { BASE_URL, TOKEN_STORAGE_KEY } from 'redux/actions/constants';
import { RouteConstants } from 'constants';

const SIGNIN_URL = `${BASE_URL}/api/user/signin`;

export const signinMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui, auth } = getState();

    switch (action.type) {
      case SIGNIN_PAGE_START:
        if (ui && ui.redirectTo && ui.redirectTo === RouteConstants.SIGNIN) {
          next(setRedirect({ to: '', feature: SIGNIN_FEATURE }));
        }

        const { currentUser } = auth;
        if (currentUser && currentUser.id && currentUser.id > 0) {
          if (ui.origin && ui.origin !== '') {
            next(setRedirect({ to: ui.origin, feature: SIGNIN_FEATURE }));
            next(setOrigin({ origin: '', feature: SIGNIN_FEATURE }));
          } else {
            next(
              setRedirect({ to: RouteConstants.HOME, feature: SIGNIN_FEATURE })
            );
          }
        }
        break;
      case SIGNIN_ACTION:
        next(setLoader({ state: true, feature: SIGNIN_FEATURE }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SIGNIN_URL,
            feature: SIGNIN_FEATURE,
          })
        );
        break;
      case `${SIGNIN_FEATURE} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));

        next(setAuth({ auth: action.payload }));
        next(
          setToStorage({
            storageKey: TOKEN_STORAGE_KEY,
            storageValue: action.payload.token,
            feature: SIGNIN_FEATURE,
          })
        );
        next(setRedirect({ to: RouteConstants.HOME, feature: SIGNIN_FEATURE }));

        break;
      case `${SIGNIN_FEATURE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: error.message,
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else if (action.payload.status === 'fail' && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: err,
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else {
          // TODO: Sonraki satır test edilecektir.
          next(
            setNotification({
              type: MessageTypes.error,
              message: action.payload.message,
              feature: SIGNIN_FEATURE,
            })
          );
        }
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: SIGNIN_FEATURE,
          })
        );
        break;
      default:
        break;
    }
  };
