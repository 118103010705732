import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useRedirection = ({ currentRoute }) => {
  const redirectTo = useSelector((state) => state.ui.redirectTo);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      redirectTo !== null &&
      redirectTo !== undefined &&
      redirectTo !== '' &&
      redirectTo !== currentRoute
    ) {
      navigate(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo, currentRoute]);
};

export default useRedirection;
