import { PAGES } from '../constants';

// feature name
export const WAITING_APPROVE_QUESTIONS_FEATURE = `${PAGES} [WaitingApproveQuestions]`;

// action types
export const WAITING_APPROVE_QUESTIONS_PAGE_START = `${WAITING_APPROVE_QUESTIONS_FEATURE} PAGE_START`;
export const GET_WAITING_APPROVE_QUESTIONS_ACTION = `${WAITING_APPROVE_QUESTIONS_FEATURE} GET_WAITING_APPROVE_QUESTIONS_ACTION`;

// action creators
export const waitingApproveQuestionsPageStart = () => ({
  type: WAITING_APPROVE_QUESTIONS_PAGE_START,
});

export const getWaitingApproveQuestionsAction = ({ filter }) => ({
  type: GET_WAITING_APPROVE_QUESTIONS_ACTION,
  payload: { filter },
});
