import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { apiMiddleware } from './middlewares/api';
import { authMiddleware } from './middlewares/auth';
import { notificationsMiddleware } from './middlewares/notifications';
// import { pagesMiddleware } from './middlewares/pages';
import * as pagesMiddlewares from './middlewares/pages';
import { storageMiddleware } from './middlewares/storage';
import { authReducer } from './reducers/auth';
import { dataReducer } from './reducers/data';
import { messageReducer } from './reducers/message';
import { notificationsReducer } from './reducers/notifications';
import { uiReducer } from './reducers/ui';

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer,
  ui: uiReducer,
  message: messageReducer,
  data: dataReducer,
});

const featureMiddlewares = [authMiddleware, ...Object.values(pagesMiddlewares)];
const coreMiddlewares = [
  apiMiddleware,
  notificationsMiddleware,
  storageMiddleware,
];

const enhancer = composeWithDevTools(
  applyMiddleware(...featureMiddlewares, ...coreMiddlewares)
);

export const store = createStore(rootReducer, {}, enhancer);
