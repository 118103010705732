/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const questionForm = {
  formId: 'question-form',
  formField: {
    usage: {
      name: 'usage',
      label: 'soru kullanım alanı',
      type: 'select',
      size: 'medium',
      isMulti: false,
      placeholder: 'Lütfen kullanım alanı seçiniz.',
      errorMsg: 'Kullanım alanı seçimi zorunludur.',
    },
    topics: {
      name: 'topics',
      label: 'konular',
      type: 'select',
      size: 'medium',
      isMulti: true,
      placeholder: 'Lütfen konu seçiniz.',
      errorMsg: 'En az bir konu seçimi zorunludur.',
    },
    subtopics: {
      name: 'subtopics',
      label: 'alt konular',
      type: 'select',
      size: 'medium',
      isMulti: true,
      placeholder: 'Lütfen alt konu seçiniz.',
      errorMsg: 'En az bir alt konu seçimi zorunludur.',
    },
    questionType: {
      name: 'questionType',
      label: 'soru tipi',
      type: 'select',
      size: 'medium',
      isMulti: false,
      placeholder: 'Soru tipi seçiniz.',
      errorMsg: 'Soru tipi seçimi zorunludur.',
    },
    difficulty: {
      name: 'difficulty',
      label: 'zorluk',
      type: 'select',
      size: 'medium',
      isMulti: false,
      placeholder: 'Zorluk derecesi seçiniz.',
      errorMsg: 'Zorluk derecesi seçimi zorunludur.',
    },
    correctAnswer: {
      name: 'correctAnswer',
      label: 'doğru cevap',
      type: 'select',
      size: 'medium',
      isMulti: false,
      placeholder: 'Doğru cevabı seçiniz.',
      errorMsg: 'Doğru cevap seçimi zorunludur.',
    },
    solutionVideoUrl: {
      name: 'solutionVideoUrl',
      label: 'çözüm video bağlantısı',
      type: 'text',
      placeholder: 'https://...',
      size: 'medium',
    },
  },
};

export default questionForm;
