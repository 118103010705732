// action types
export const SET_DATA = 'SET_DATA';
export const REMOVE_DATA = 'REMOVE_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';

// action creators
export const setData = ({ data, feature }) => ({
  type: `${feature} ${SET_DATA}`,
  payload: data,
  meta: { feature },
});

export const removeData = ({ feature, where }) => ({
  type: `${feature} ${REMOVE_DATA}`,
  payload: { where },
  meta: { feature },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});
