const LanguageConstants = {
  HOME: 'anasayfa',
  QUESTIONS: 'sorular',
  QUESTION: 'soru',
  OWN_QUESTIONS: 'sorularım',
  NEW_QUESTION: 'yeni soru',
  EDIT_QUESTION: 'soruyu düzenle',
  OTHER_QUESTIONS: 'yönetici',
  WAITING_APPROVE: 'onay bekleyenler',
  SIGN_IN: 'giriş',
  SIGN_OUT: 'çıkış',
  MY_WAITING_APPROVE_QUESTIONS: 'onay bekleyenler',
  MY_APPROVED_QUESTIONS: 'onaylananlar',
  MY_DENIED_QUESTIONS: 'revize bekleyenler',
  WAITING_APPROVE_QUESTIONS: 'onay bekleyenler',
  APPROVED_QUESTIONS: 'onaylananlar',
  DENIED_QUESTIONS: 'revize bekleyenler',

  // Question Usage Types
  SOLVING: 'soru çözüm',
  TESTING: 'test',
  PILOT_TEST: 'deneme',

  // Question Types
  NEW_TYPE: 'yeni nesil',
  CLASSIC: 'standart',
};

export default LanguageConstants;

// creation = 'CREATION',
// waitingApprove = 'WAITING_APPROVE',
// denied = 'DENIED',
// deleted = 'DELETED',
// approved = 'APPROVED',
// needCheck = 'NEED_CHECK',
