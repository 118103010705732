/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// react-router components
import { useLocation, Link, useNavigate } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
// import ArgonInput from 'components/ArgonInput';

// Argon Dashboard 2 PRO MUI example components
import Breadcrumbs from '../Breadcrumbs';
import NotificationItem from '../Items/NotificationItem';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from './styles';

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setTransparentNavbar,
  setMiniSidenav,
} from 'context';

// Images
import team2 from 'assets/images/team-2.jpg';
import logoSpotify from 'assets/images/small-logos/logo-spotify.svg';
import { LanguageConstants, RouteConstants } from 'constants';
import { connect } from 'react-redux';
import { signoutAction } from 'redux/actions/pages';

function DashboardNavbar({ absolute, light, isMini, signoutAction, auth }) {
  const navigate = useNavigate();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={['New message', 'from Laur']}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={['New album', 'by Travis Scott']}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon
            fontSize="small"
            sx={{ color: ({ palette: { white } }) => white.main }}
          >
            payment
          </Icon>
        }
        title={['', 'Payment successfully completed']}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme, { navbarType })}>
        <ArgonBox
          color={light && transparentNavbar ? 'white' : 'dark'}
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={transparentNavbar ? light : false}
          />
          <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? 'menu_open' : 'menu'}
          </Icon>
        </ArgonBox>
        {isMini ? null : (
          <ArgonBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <ArgonBox pr={1}>
              <ArgonInput
                placeholder="Type here..."
                startAdornment={
                  <Icon fontSize="small" style={{ marginRight: '6px' }}>
                    search
                  </Icon>
                }
              />
            </ArgonBox> */}
            <ArgonBox color={light ? 'white' : 'inherit'}>
              {auth?.currentUser?.id > 0 ? (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    signoutAction();
                    navigate(RouteConstants.SIGNIN, { replace: true });
                  }}
                >
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color:
                          light && transparentNavbar ? white.main : dark.main,
                      })}
                    >
                      logout
                    </Icon>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      textTransform="capitalize"
                      color={light && transparentNavbar ? 'white' : 'dark'}
                    >
                      {LanguageConstants.SIGN_OUT}
                    </ArgonTypography>
                  </IconButton>
                </Link>
              ) : (
                <Link to={RouteConstants.SIGNIN}>
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color:
                          light && transparentNavbar ? white.main : dark.main,
                      })}
                    >
                      account_circle
                    </Icon>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      textTransform="capitalize"
                      color={light && transparentNavbar ? 'white' : 'dark'}
                    >
                      {LanguageConstants.SIGN_IN}
                    </ArgonTypography>
                  </IconButton>
                </Link>
              )}

              <IconButton
                size="small"
                color={light && transparentNavbar ? 'white' : 'dark'}
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? 'menu_open' : 'menu'}</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                color={light && transparentNavbar ? 'white' : 'dark'}
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton> */}
              <IconButton
                size="small"
                color={light && transparentNavbar ? 'white' : 'dark'}
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </ArgonBox>
          </ArgonBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
  auth: null,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  signoutAction: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { signoutAction };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
