import { PAGES } from '../constants';

// feature name
export const EDIT_QUESTION_FEATURE = `${PAGES} [EditQuestion]`;

// action types
export const EDIT_QUESTION_PAGE_START = `${EDIT_QUESTION_FEATURE} PAGE_START`;
export const SEND_APPROVE_ACTION = `${EDIT_QUESTION_FEATURE} SEND_APPROVE_ACTION`;
export const APPROVE_QUESTION_ACTION = `${EDIT_QUESTION_FEATURE} APPROVE_QUESTION_ACTION`;
export const DENY_QUESTION_ACTION = `${EDIT_QUESTION_FEATURE} DENY_QUESTION_ACTION`;
export const SAVE_QUESTION_ACTION = `${EDIT_QUESTION_FEATURE} SAVE_QUESTION_ACTION`;
export const SAVE_APPROVER_NOTE_ACTION = `${EDIT_QUESTION_FEATURE} SAVE_APPROVER_NOTE_ACTION`;

// action creators
export const editQuestionPageStart = ({ questionId }) => ({
  type: EDIT_QUESTION_PAGE_START,
  payload: { questionId },
});

export const sendApproveAction = ({ question }) => ({
  type: SEND_APPROVE_ACTION,
  payload: { question },
});

export const approveQuestionAction = ({ question }) => ({
  type: APPROVE_QUESTION_ACTION,
  payload: { question },
});

export const denyQuestionAction = ({ question }) => ({
  type: DENY_QUESTION_ACTION,
  payload: { question },
});

export const saveQuestionAction = ({ question }) => ({
  type: SAVE_QUESTION_ACTION,
  payload: { question },
});

export const saveApproverNoteAction = ({ question }) => ({
  type: SAVE_APPROVER_NOTE_ACTION,
  payload: { question },
});
