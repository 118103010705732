/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI example components
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useRedirection from 'hooks/useRedirection';

import {
  DashboardLayout,
  DashboardNavbar,
  Footer,
} from 'features/default-layout';

import QuestionForms from '../components/question-forms';
import { connect } from 'react-redux';
import {
  editQuestionPageStart,
  getTopicsAction,
  getSubtopicsAction,
} from 'redux/actions/pages';
import Notification from 'components/Notification';
import Loading from 'components/Loading';
import { RouteConstants } from 'constants';

const EditQuestionPage = ({
  editQuestionPageStart,
  getTopicsAction,
  getSubtopicsAction,
  question,
  route,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (question === null || question === undefined || question.id < 1) {
      navigate(RouteConstants.HOME);
    } else {
      editQuestionPageStart({ questionId: question.id });
      getTopicsAction();
      getSubtopicsAction();
    }
    // eslint-disable-next-line
  }, []);

  useRedirection({ currentRoute: route });

  return (
    <>
      <Loading />
      <Notification />
      <DashboardLayout>
        <DashboardNavbar />
        <QuestionForms
          title="Soru Düzenle"
          description="Soru düzenleme ekranı"
          question={question}
        />
        <Footer />
      </DashboardLayout>
    </>
  );
};

EditQuestionPage.defaultProps = {
  question: null,
};

EditQuestionPage.propTypes = {
  question: PropTypes.objectOf(PropTypes.any),
  editQuestionPageStart: PropTypes.func.isRequired,
  getTopicsAction: PropTypes.func.isRequired,
  getSubtopicsAction: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
};

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = {
  editQuestionPageStart,
  getTopicsAction,
  getSubtopicsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestionPage);
