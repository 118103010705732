import React from 'react';
import PropTypes from 'prop-types';
import { ArrowFatUp, X } from 'phosphor-react';
import { Tooltip } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';

import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import './_custom-upload-image.css';

const CustomUploadImage = ({
  label,
  removeLabel,
  height,
  url,
  removeBtnRight,
  onImageRemove,
  onImageChange,
  loading,
  fileInputKey,
}) => (
  <div className="upload-container">
    <ArgonButton
      variant="text"
      size="small"
      aria-label={label ?? 'Yükle'}
      component="label"
      style={{ padding: 0 }}
    >
      <ArgonBox
        className="image-container"
        bgColor="#f1f3f4"
        style={{
          minHeight: `${height ?? 120}px`,
          width: '100%',
        }}
        width="100%"
        // height={height ? `${height}px` : `120px`}
      >
        {url && url !== '' ? (
          <img src={url} alt="Soru/Çözüm Görseli" />
        ) : // <embed
        //   src={url}
        //   type="application/pdf"
        //   width="100%"
        //   height={`${height}px`}
        // />
        loading ? (
          <div className="image-uploading">
            <ClipLoader color="#7493b6" size={35} />
          </div>
        ) : (
          <div className="upload-image-placeholder">
            <ArrowFatUp
              size={height ? height / 2 : 100}
              weight="duotone"
              color="#7493b6"
            />
            <span>{label ?? 'Yükle'}</span>
          </div>
        )}
      </ArgonBox>
      <input
        name="file"
        type="file"
        accept="image/*"
        hidden
        onChange={onImageChange}
        key={fileInputKey}
      />
    </ArgonButton>
    {url && url !== '' ? (
      <Tooltip title={removeLabel ?? 'Kaldır'} placement="top">
        <div
          onClick={onImageRemove}
          aria-hidden
          className="image-remove"
          style={{
            top: 0,
            right: removeBtnRight ?? 0,
            cursor: onImageRemove ? 'pointer' : 'default',
          }}
        >
          <X weight="duotone" size={16} color="#E3E9F0" />
        </div>
      </Tooltip>
    ) : null}
  </div>
);

CustomUploadImage.defaultProps = {
  label: null,
  removeLabel: null,
  height: null,
  url: '',
  removeBtnRight: null,
  onImageRemove: null,
  loading: false,
  fileInputKey: '',
};

CustomUploadImage.propTypes = {
  label: PropTypes.string,
  removeLabel: PropTypes.string,
  height: PropTypes.number,
  url: PropTypes.string,
  removeBtnRight: PropTypes.string,
  onImageRemove: PropTypes.func,
  onImageChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fileInputKey: PropTypes.string,
};

export default CustomUploadImage;
