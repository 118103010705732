import { PAGES } from '../constants';

// feature name
export const MY_WAITING_APPROVE_QUESTIONS_FEATURE = `${PAGES} [MyWaitingApproveQuestions]`;

// action types
export const MY_WAITING_APPROVE_QUESTIONS_PAGE_START = `${MY_WAITING_APPROVE_QUESTIONS_FEATURE} PAGE_START`;
export const GET_MY_WAITING_APPROVE_QUESTIONS_ACTION = `${MY_WAITING_APPROVE_QUESTIONS_FEATURE} GET_MY_WAITING_APPROVE_QUESTIONS_ACTION`;

// action creators
export const myWaitinqApproveQuestionsPageStart = () => ({
  type: MY_WAITING_APPROVE_QUESTIONS_PAGE_START,
});

export const getMyWaitingApproveQuestionsAction = ({ filter }) => ({
  type: GET_MY_WAITING_APPROVE_QUESTIONS_ACTION,
  payload: { filter },
});
