/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// react-router-dom components
// import { Link } from 'react-router-dom';

// @mui material components
import Switch from '@mui/material/Switch';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonInput from 'components/ArgonInput';
import ArgonButton from 'components/ArgonButton';

// Authentication layout components
import IllustrationLayout from './components/IllustrationLayout';

import lockImage from 'assets/images/illustrations/dark-lock-ill.png';
import { connect } from 'react-redux';
import { signinAction, signinPageStart } from 'redux/actions/pages';
import { RouteConstants } from 'constants';
import Loading from 'components/Loading';
import Notification from 'components/Notification';
import useRedirection from 'hooks/useRedirection';

function SigninPage({ auth, signinAction, signinPageStart }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    signinPageStart();
    // eslint-disable-next-line
  }, [auth]);

  useRedirection({ currentRoute: RouteConstants.SIGNIN });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onSigninClick = (e) => {
    e.preventDefault();
    signinAction({ email, password });
  };

  return (
    <>
      <Loading />
      <Notification />
      <IllustrationLayout
        title="Giriş Yap"
        description="Lütfen giriş yapmak için e-posta ve şifre kullanınız."
        illustration={{
          image: lockImage,
          title: 'Yönetim Paneli',
          description:
            'Giriş yapmaya çalıştığınız uygulama sadece yetkili öğretmenler içindir. Yetkiniz yoksa lütfen giriş yapmaya çalışmayınız.',
        }}
      >
        <ArgonBox component="form" role="form" onSubmit={onSigninClick}>
          <ArgonBox mb={2}>
            <ArgonInput
              type="email"
              placeholder="Eposta"
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ArgonBox>
          <ArgonBox mb={2}>
            <ArgonInput
              type="password"
              placeholder="Şifre"
              size="large"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </ArgonBox>
          <ArgonBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <ArgonTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: 'pointer', userSelect: 'none' }}
            >
              &nbsp;&nbsp;Beni hatırla
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox mt={4} mb={1}>
            <ArgonButton type="submit" color="info" size="large" fullWidth>
              GİRİŞ
            </ArgonButton>
          </ArgonBox>
          {/* <ArgonBox mt={3} textAlign="center">
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{' '}
            <ArgonTypography
              component={Link}
              to="/authentication/sign-up/illustration"
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Sign up
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox> */}
        </ArgonBox>
      </IllustrationLayout>
    </>
  );
}

SigninPage.defaultProps = {
  auth: null,
};

SigninPage.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any),
  signinAction: PropTypes.func.isRequired,
  signinPageStart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { signinAction, signinPageStart };

export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
