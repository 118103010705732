import { PAGES } from '../constants';

// feature name
export const COMMON_FEATURE = `${PAGES} [Common]`;

// action types
export const GET_TOPICS_ACTION = `${COMMON_FEATURE} GET_TOPICS_ACTION`;
export const GET_SUBTOPICS_ACTION = `${COMMON_FEATURE} GET_SUBTOPICS_ACTION`;
export const GET_USERS_ACTION = `${COMMON_FEATURE} GET_USERS_ACTION`;
// action creators
export const getTopicsAction = () => ({
  type: GET_TOPICS_ACTION,
});

export const getSubtopicsAction = () => ({
  type: GET_SUBTOPICS_ACTION,
});

export const getUsersAction = () => ({
  type: GET_USERS_ACTION,
});
