import React from 'react';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { connect } from 'react-redux';
import './_custom-loading.css';
import { GENERAL_LOADING_IDENTIFIER } from 'redux/actions/ui';

const Loading = ({ loading, identifier }) =>
  loading ? (
    <div
      className={
        !identifier || identifier === GENERAL_LOADING_IDENTIFIER
          ? 'loading whole-screen'
          : 'loading whole-element'
      }
    >
      <ScaleLoader
        color="#11cdef"
        size={
          !identifier || identifier === GENERAL_LOADING_IDENTIFIER ? 50 : 25
        }
      />
    </div>
  ) : null;

Loading.defaultProps = {
  loading: false,
};

Loading.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  loading:
    ownProps.identifier && ownProps.identifier !== ''
      ? state.ui.loading[ownProps.identifier]
      : state.ui.loading[GENERAL_LOADING_IDENTIFIER],
});

export default connect(mapStateToProps, {})(Loading);
