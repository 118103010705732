import { PAGES } from '../constants';

// feature name
export const MY_APPROVED_QUESTIONS_FEATURE = `${PAGES} [MyApprovedQuestions]`;

// action types
export const MY_APPROVED_QUESTIONS_PAGE_START = `${MY_APPROVED_QUESTIONS_FEATURE} PAGE_START`;
export const GET_MY_APPROVED_QUESTIONS_ACTION = `${MY_APPROVED_QUESTIONS_FEATURE} GET_MY_APPROVED_QUESTIONS_ACTION`;

// action creators
export const myApprovedQuestionsPageStart = () => ({
  type: MY_APPROVED_QUESTIONS_PAGE_START,
});

export const getMyApprovedQuestionsAction = ({ filter }) => ({
  type: GET_MY_APPROVED_QUESTIONS_ACTION,
  payload: { filter },
});
