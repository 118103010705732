/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// NewProduct page components
import FormField from '../FormField';
import { connect } from 'react-redux';
import CustomUploadImage from '../CustomUploadImage';
import { CorrectAnswers } from 'enumerations';

function QuestionSpecificInfo({
  formData,
  questionImageUploading,
  questionImageUrl,
  onQuestionImageChange,
  onQuestionImageRemove,
  validateImageUrl,
  solutionImageUploading,
  solutionImageUrl,
  onSolutionImageChange,
  onSolutionImageRemove,
  fileInputKey,
  solutionFileInputKey,
}) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
  } = formData;

  const {
    correctAnswer: { errorMsg: _correctAnswer, ...correctAnswerRest },
    solutionVideoUrl: { ...solutionVideoUrlRest },
  } = formField;

  const { correctAnswer: correctAnswerV, solutionVideoUrl: solutionVideoUrlV } =
    values;

  return (
    <ArgonBox mb={3}>
      <Card sx={{ overflow: 'visible' }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Soruya Özel Özellikler</ArgonTypography>
          <ArgonBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Soru Görseli Yükle
                  </ArgonTypography>
                </ArgonBox>
                <CustomUploadImage
                  // height={120}
                  loading={questionImageUploading}
                  url={questionImageUrl}
                  removeBtnRight="0"
                  onImageRemove={onQuestionImageRemove}
                  onImageChange={onQuestionImageChange}
                  fileInputKey={fileInputKey}
                />
                <ArgonBox mt={0.75}>
                  <ArgonTypography
                    component="div"
                    variant="caption"
                    color="error"
                  >
                    {validateImageUrl &&
                    (questionImageUrl === '' ||
                      questionImageUrl === null ||
                      questionImageUrl === undefined)
                      ? 'Soru görseli yüklemek zorunludur.'
                      : ''}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Çözüm Görseli Yükle
                  </ArgonTypography>
                </ArgonBox>
                <CustomUploadImage
                  // height={120}
                  loading={solutionImageUploading}
                  url={solutionImageUrl}
                  removeBtnRight="0"
                  onImageRemove={onSolutionImageRemove}
                  onImageChange={onSolutionImageChange}
                  fileInputKey={solutionFileInputKey}
                />
              </Grid>
            </Grid>
          </ArgonBox>
          <ArgonBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  {...correctAnswerRest}
                  options={Object.values(CorrectAnswers).map(
                    (correctAnswerType) => {
                      return {
                        label: correctAnswerType,
                        value: correctAnswerType,
                      };
                    }
                  )}
                  value={correctAnswerV}
                  error={
                    errors.correctAnswer?.length > 0 &&
                    !(touched.correctAnswer !== true)
                  }
                  success={correctAnswerV && !errors.correctAnswer}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  {...solutionVideoUrlRest}
                  value={solutionVideoUrlV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </ArgonBox>
  );
}

QuestionSpecificInfo.defaultProps = {
  questionImageUploading: false,
  questionImageUrl: '',
  validateImageUrl: false,
  solutionImageUploading: false,
  solutionImageUrl: '',
  fileInputKey: '',
  solutionFileInputKey: '',
};

QuestionSpecificInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  questionImageUploading: PropTypes.bool,
  questionImageUrl: PropTypes.string,
  onQuestionImageChange: PropTypes.func.isRequired,
  onQuestionImageRemove: PropTypes.func.isRequired,
  validateImageUrl: PropTypes.bool,
  solutionImageUploading: PropTypes.bool,
  solutionImageUrl: PropTypes.string,
  onSolutionImageChange: PropTypes.func.isRequired,
  onSolutionImageRemove: PropTypes.func.isRequired,
  fileInputKey: PropTypes.string,
  solutionFileInputKey: PropTypes.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionSpecificInfo);
