/* eslint-disable no-case-declarations */
import { setRedirect } from 'redux/actions/ui';
import { HOME_FEATURE, HOME_PAGE_START } from 'redux/actions/pages';
import { RouteConstants } from 'constants';

export const homeMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui } = getState();

    switch (action.type) {
      case HOME_PAGE_START:
        if (ui && ui.redirectTo && ui.redirectTo === RouteConstants.HOME) {
          next(setRedirect({ to: '', feature: HOME_FEATURE }));
        }
        break;
      default:
        break;
    }
  };
