const QuestionDifficultyTypes = Object.freeze({
  one: 1,
  two: 2,
  three: 3,
});

const getQuestionDifficultyTypeFromString = (value) => {
  switch (value) {
    case '1':
      return QuestionDifficultyTypes.one;
    case '2':
      return QuestionDifficultyTypes.two;
    case '3':
      return QuestionDifficultyTypes.three;
    default:
      return null;
  }
};

const getQuestionDifficultyTypeFromNumber = (value) => {
  return getQuestionDifficultyTypeFromString(value.toString(10));
};

export {
  QuestionDifficultyTypes,
  getQuestionDifficultyTypeFromString,
  getQuestionDifficultyTypeFromNumber,
};
