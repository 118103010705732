import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import { connect } from 'react-redux';
import { denyQuestionAction } from 'redux/actions/pages';
import { Dialog, DialogContent } from '@mui/material';
import ArgonInput from 'components/ArgonInput';

const DenyButton = ({
  setValidateImageUrl,
  handleSubmit,
  values,
  errors,
  questionImageUrl,
  solutionImageUrl,
  questionId,
  denyQuestionAction,
  denyNote,
}) => {
  const [openApproverNoteDialog, setOpenApprovedNoteDialog] = useState(false);
  const [approverNote, setApproverNote] = useState('');

  useEffect(() => {
    if (denyNote !== null && denyNote !== undefined && denyNote !== '') {
      setApproverNote(denyNote);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="sm"
        open={openApproverNoteDialog}
      >
        <DialogContent>
          <ArgonBox m={2}>
            <ArgonTypography
              component="label"
              variant="h5"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Onaycı Notu
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox m={2}>
            <ArgonInput
              multiline
              rows={5}
              placeholder="Lütfen notunuzu giriniz."
              value={approverNote}
              onChange={(e) => setApproverNote(e.target.value)}
            />
          </ArgonBox>
          <ArgonBox m={2} mt={4} display="flex" justifyContent="flex-end">
            <ArgonBox mr={2}>
              <ArgonButton
                variant="text"
                color="dark"
                onClick={() => {
                  setOpenApprovedNoteDialog(false);
                }}
              >
                Vazgeç
              </ArgonButton>
            </ArgonBox>
            <ArgonButton
              variant="contained"
              color="error"
              onClick={() => {
                if (
                  approverNote !== null &&
                  approverNote !== undefined &&
                  approverNote !== ''
                ) {
                  const submittedQuestion = {
                    id: questionId,
                    questionUrl: questionImageUrl,
                    usage: values?.usage?.value ?? null,
                    questionType: values?.questionType?.value ?? null,
                    difficulty: values?.difficulty?.value ?? 0,
                    correctAnswer: values?.correctAnswer?.value ?? null,
                    videoAnswerUrl: values?.solutionVideoUrl ?? null,
                    imageAnswerUrl: solutionImageUrl,
                    subTopics: values?.subtopics?.map((st) => st.value),
                    approverNote,
                  };

                  denyQuestionAction({
                    question: submittedQuestion,
                  });

                  setOpenApprovedNoteDialog(false);
                }
              }}
            >
              Revize Talep Et
            </ArgonButton>
          </ArgonBox>
        </DialogContent>
      </Dialog>
      <ArgonBox ml={2}>
        <ArgonButton
          variant="contained"
          color="error"
          onClick={() => {
            setValidateImageUrl(true);

            handleSubmit();

            if (Object.keys(errors).length > 0) {
              return;
            }

            if (
              questionImageUrl === null ||
              questionImageUrl === undefined ||
              questionImageUrl === ''
            ) {
              return;
            }

            setOpenApprovedNoteDialog(true);

            // const submittedQuestion = {
            //   id: questionId,
            //   questionUrl: questionImageUrl,
            //   usage: values?.usage?.value ?? null,
            //   questionType: values?.questionType?.value ?? null,
            //   difficulty: values?.difficulty?.value ?? 0,
            //   correctAnswer: values?.correctAnswer?.value ?? null,
            //   videoAnswerUrl: values?.solutionVideoUrl ?? null,
            //   imageAnswerUrl: solutionImageUrl,
            //   subTopics: values?.subtopics?.map((st) => st.value),
            // };

            // denyQuestionAction({
            //   question: submittedQuestion,
            // });
          }}
        >
          Revize Talep Et
        </ArgonButton>
      </ArgonBox>
    </>
  );
};

DenyButton.defaultProps = {
  values: null,
  errors: null,
  questionImageUrl: '',
  solutionImageUrl: '',
  approverNote: '',
};

DenyButton.propTypes = {
  setValidateImageUrl: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  questionImageUrl: PropTypes.string,
  solutionImageUrl: PropTypes.string,
  questionId: PropTypes.number.isRequired,
  denyQuestionAction: PropTypes.func.isRequired,
  approverNote: PropTypes.string,
};

const mapStateToProps = (_) => ({});

const mapDispatchToProps = {
  denyQuestionAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DenyButton);
