import { PAGES } from '../constants';

// feature name
export const HOME_FEATURE = `${PAGES} [Home]`;

// action types
export const HOME_PAGE_START = `${HOME_FEATURE} PAGE_START`;

// action creators
export const homePageStart = () => ({
  type: HOME_PAGE_START,
});
