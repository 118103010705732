/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Argon Dashboard 2 PRO MUI example components
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';
// import SidenavFooter from './SidenavFooter';

// Custom styles for the Sidenav
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';

// Argon Dashboard 2 PRO MUI context
import { useArgonController, setMiniSidenav } from 'context';
import { StringHelpers } from 'helpers';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, darkSidenav, layout } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const itemName = pathname.split('/').slice(1)[1];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener('resize', handleMiniSidenav);

    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(
      ({ name, route, key, href, showInSidenav }) => {
        if (showInSidenav === false) {
          return null;
        }
        return href ? (
          <Link key={key} href={href} target="_blank" rel="noreferrer">
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavItem name={name} active={route === pathname} nested />
          </NavLink>
        );
      }
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key, showInSidenav }) => {
      let returnValue;

      if (collapse) {
        if (showInSidenav !== false) {
          returnValue = (
            <SidenavItem
              key={key}
              name={StringHelpers.capitalizeWordsString(name) ?? name}
              active={key === itemName}
              open={openNestedCollapse === name}
              onClick={() =>
                openNestedCollapse === name
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(name)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = renderNestedCollapse(collapse);
        }
      } else {
        if (showInSidenav !== false) {
          returnValue = href ? (
            <Link href={href} key={key} target="_blank" rel="noreferrer">
              <SidenavItem
                name={StringHelpers.capitalizeWordsString(name) ?? name}
                active={key === itemName}
              />
            </Link>
          ) : (
            <NavLink to={route} key={key}>
              <SidenavItem
                name={StringHelpers.capitalizeWordsString(name) ?? name}
                active={key === itemName}
              />
            </NavLink>
          );
        } else {
          return null;
        }
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      showInSidenav,
    }) => {
      let returnValue;

      if (type === 'collapse') {
        if (href && showInSidenav !== false) {
          returnValue = (
            <Link href={href} key={key} target="_blank" rel="noreferrer">
              <SidenavCollapse
                name={StringHelpers.capitalizeWordsString(name) ?? name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={StringHelpers.capitalizeWordsString(name) ?? name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          if (showInSidenav !== false) {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={StringHelpers.capitalizeWordsString(name) ?? name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          } else {
            returnValue = collapse ? renderCollapse(collapse) : null;
          }
        }
      } else if (type === 'title') {
        returnValue = (
          <ArgonTypography
            key={key}
            color={darkSidenav ? 'white' : 'dark'}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </ArgonTypography>
        );
      } else if (type === 'divider') {
        returnValue = <Divider key={key} light={darkSidenav} />;
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ darkSidenav, miniSidenav, layout }}
    >
      <ArgonBox pt={3} pb={1} px={4} textAlign="center">
        <ArgonBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <ArgonTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox
          component={NavLink}
          to="/"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {brand && (
            <ArgonBox
              component="img"
              src={brand}
              alt="Kazanırım Logo"
              width="100%"
              mr={0.25}
            />
          )}
          <ArgonBox
            width={!brandName && '100%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <ArgonTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={darkSidenav ? 'white' : 'dark'}
            >
              {brandName}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
      <Divider light={darkSidenav} />
      <List>{renderRoutes}</List>

      {/* <ArgonBox pt={1} mt="auto" mb={2} mx={2}>
        <SidenavFooter />
      </ArgonBox> */}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
