import { PAGES } from '../constants';

// feature name
export const NEW_QUESTION_FEATURE = `${PAGES} [NewQuestion]`;

// action types
export const NEW_QUESTION_PAGE_START = `${NEW_QUESTION_FEATURE} PAGE_START`;
export const INSERT_NEW_QUESTION_ACTION = `${NEW_QUESTION_FEATURE} INSERT_NEW_QUESTION_ACTION`;

// action creators
export const newQuestionPageStart = () => ({
  type: NEW_QUESTION_PAGE_START,
});

export const insertNewQuestionAction = ({ question }) => ({
  type: INSERT_NEW_QUESTION_ACTION,
  payload: { question },
});
