class StringHelpers {
  static capitalizeWordArray(arr) {
    return arr?.map((element) => {
      const lowerElement = element.toLocaleLowerCase('TR');
      return (
        lowerElement.charAt(0).toLocaleUpperCase('TR') +
        lowerElement.slice(1).toLowerCase()
      );
    });
  }

  static capitalizeWordsString(words) {
    if (words === null || words === undefined || typeof words !== 'string') {
      return null;
    }
    return this.capitalizeWordArray(words.split(' '))?.join(' ');
  }

  static capitalizeFirstLetterOfFirstWord(words) {
    if (words === null || words === undefined || typeof words !== 'string') {
      return null;
    }
    return (
      words.toLocaleLowerCase('TR').charAt(0).toLocaleUpperCase('TR') +
      words.slice(1).toLowerCase()
    );
  }
}

export default StringHelpers;
