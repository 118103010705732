/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Argon Dashboard 2 PRO MUI example components
import { Sidenav } from './features/default-layout';

// Argon Dashboard 2 PRO MUI themes
import theme from 'assets/theme';

// Argon Dashboard 2 PRO MUI routes
import routes from 'routes';

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController, setMiniSidenav } from 'context';

// Images
import brand from 'assets/images/kazanirim/sidenav-logo.png';

// Redux
import { connect } from 'react-redux';
import { startAuth } from 'redux/actions/auth';

// Kvkk Pages
import Kvkk from 'features/kvkk';
import KvkkAndroid from 'features/kvkk/kvkk-android';

// Icon Fonts
import 'assets/css/nucleo-icons.css';
import 'assets/css/nucleo-svg.css';

const App = ({ startAuth, auth }) => {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    startAuth();
    // eslint-disable-next-line
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Kazanırım LGS Matematik"
            routes={routes(auth?.currentUser?.userType)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes(auth?.currentUser?.userType))}
        <Route exact path="kvkk" key="kvkk" element={<Kvkk />} />
        <Route
          exact
          path="kvkk-android"
          key="kvkk-android"
          element={<KvkkAndroid />}
        />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </ThemeProvider>
  );
};

App.defaultProps = {
  auth: null,
};

App.propTypes = {
  startAuth: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { startAuth };

export default connect(mapStateToProps, mapDispatchToProps)(App);
