import { PAGES } from '../constants';

// feature name
export const APPROVED_QUESTIONS_FEATURE = `${PAGES} [ApprovedQuestions]`;

// action types
export const APPROVED_QUESTIONS_PAGE_START = `${APPROVED_QUESTIONS_FEATURE} PAGE_START`;
export const GET_APPROVED_QUESTIONS_ACTION = `${APPROVED_QUESTIONS_FEATURE} GET_APPROVED_QUESTIONS_ACTION`;

// action creators
export const approvedQuestionsPageStart = () => ({
  type: APPROVED_QUESTIONS_PAGE_START,
});

export const getApprovedQuestionsAction = ({ filter }) => ({
  type: GET_APPROVED_QUESTIONS_ACTION,
  payload: { filter },
});
