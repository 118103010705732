/* eslint-disable no-case-declarations */
import { clearUi } from 'redux/actions/ui';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setAuth } from 'redux/actions/auth';
import { removeFromStorage } from 'redux/actions/storage';
import { SIGNOUT_ACTION } from 'redux/actions/pages';
import { BASE_URL, TOKEN_STORAGE_KEY } from 'redux/actions/constants';
// import { RouteConstants } from 'constants';
import { clearData } from 'redux/actions/data';

const SIGNOUT_URL = `${BASE_URL}/api/user/signout`;

export const navbarMiddleware = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case SIGNOUT_ACTION:
      // next(setLoader({ state: true, feature: SIGNOUT_ACTION }));
      next(
        apiRequest({
          method: 'POST',
          url: SIGNOUT_URL,
          feature: SIGNOUT_ACTION,
        })
      );
      break;
    case `${SIGNOUT_ACTION} ${API_SUCCESS}`:
      // next(setLoader({ state: false, feature: SIGNOUT_ACTION }));

      next(setAuth({ auth: {} }));
      next(clearData());
      next(clearUi({ feature: SIGNOUT_ACTION }));
      next(
        removeFromStorage({
          storageKey: TOKEN_STORAGE_KEY,
          feature: SIGNOUT_ACTION,
        })
      );
      // next(setRedirect({ to: RouteConstants.SIGNIN, feature: SIGNOUT_ACTION }));

      break;
    case `${SIGNOUT_ACTION} ${API_ERROR}`:
      // next(setLoader({ state: false, feature: SIGNOUT_ACTION }));

      next(setAuth({ auth: {} }));
      next(clearData());
      next(clearUi({ feature: SIGNOUT_ACTION }));
      next(
        removeFromStorage({
          storageKey: TOKEN_STORAGE_KEY,
          feature: SIGNOUT_ACTION,
        })
      );
      // next(
      //   setRedirect({
      //     to: RouteConstants.SIGNIN,
      //     feature: SIGNOUT_ACTION,
      //   })
      // );

      break;
    default:
      break;
  }
};
