import { PAGES } from '../constants';

// feature name
export const DENIED_QUESTIONS_FEATURE = `${PAGES} [DeniedQuestions]`;

// action types
export const DENIED_QUESTIONS_PAGE_START = `${DENIED_QUESTIONS_FEATURE} PAGE_START`;
export const GET_DENIED_QUESTIONS_ACTION = `${DENIED_QUESTIONS_FEATURE} GET_DENIED_QUESTIONS_ACTION`;

// action creators
export const deniedQuestionsPageStart = () => ({
  type: DENIED_QUESTIONS_PAGE_START,
});

export const getDeniedQuestionsAction = ({ filter }) => ({
  type: GET_DENIED_QUESTIONS_ACTION,
  payload: { filter },
});
