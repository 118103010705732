import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltipWhiteSpace = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    whiteSpace: 'pre-line',
    backgroundColor: '#e9ecef',
    color: 'black',
    fontSize: '10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#e9ecef',
  },
});

export default CustomTooltipWhiteSpace;
