// general loading identifier
export const GENERAL_LOADING_IDENTIFIER = 'GENERAL_LOADING';

// action types
export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_LOADER = 'SET_LOADER';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_FILTER = 'SET_FILTER';
export const SET_BACKROUTE = 'SET_BACKROUTE';
export const CLEAR_UI = 'CLEAR_UI';

// action creators
export const setRedirect = ({ to, feature }) => ({
  type: `${feature} ${SET_REDIRECT}`,
  payload: to,
  meta: { feature },
});

export const setLoader = ({ state, feature, identifier }) => ({
  type: `${feature} ${SET_LOADER}`,
  payload: state,
  meta: { identifier: identifier ?? GENERAL_LOADING_IDENTIFIER },
});

export const setOrigin = ({ origin, feature }) => ({
  type: `${feature} ${SET_ORIGIN}`,
  payload: origin,
  meta: { feature },
});

export const setFilter = ({
  topics,
  subtopics,
  questionType,
  usage,
  questionState,
  page,
  size,
  feature,
  createdBy,
  questionId,
}) => ({
  type: `${feature} ${SET_FILTER}`,
  payload: {
    topics,
    subtopics,
    questionType,
    usage,
    questionState,
    page,
    size,
    createdBy,
    questionId,
  },
  meta: { feature },
});

export const setBackroute = ({ route, questionId, feature }) => ({
  type: `${feature} ${SET_BACKROUTE}`,
  payload: { route, questionId },
  meta: { feature },
});

export const clearUi = ({ feature }) => ({
  type: `${feature} ${CLEAR_UI}`,
});
