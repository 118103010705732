/* eslint-disable no-case-declarations */
import { MessageTypes } from 'enumerations';
import {
  setRedirect,
  setLoader,
  setFilter,
  setBackroute,
} from 'redux/actions/ui';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setNotification } from 'redux/actions/notifications';
import {
  MY_DENIED_QUESTIONS_FEATURE,
  MY_DENIED_QUESTIONS_PAGE_START,
  GET_MY_DENIED_QUESTIONS_ACTION,
} from 'redux/actions/pages';
import { BASE_URL } from 'redux/actions/constants';
import { RouteConstants } from 'constants';
import { setData } from 'redux/actions/data';
import { QuestionStateTypes } from 'enumerations';

const GET_MY_DENIED_QUESTIONS_URL = `${BASE_URL}/api/question/get-question-list-for-owner`;

const GET_MY_DENIED_QUESTIONS_ACTION_ERROR_MSG =
  'Revize beklenen sorularım alınırken hata meydana geldi.';

export const myDeniedQuestionsMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui } = getState();

    switch (action.type) {
      case MY_DENIED_QUESTIONS_PAGE_START:
        if (
          ui &&
          ui.redirectTo &&
          ui.redirectTo === RouteConstants.MY_DENIED_QUESTIONS
        ) {
          next(
            setRedirect({
              to: '',
              feature: MY_DENIED_QUESTIONS_FEATURE,
            })
          );
        }

        next(
          setBackroute({
            route: RouteConstants.MY_DENIED_QUESTIONS,
            questionId: null,
            feature: MY_DENIED_QUESTIONS_FEATURE,
          })
        );
        break;
      case GET_MY_DENIED_QUESTIONS_ACTION:
        next(
          setLoader({
            state: true,
            feature: GET_MY_DENIED_QUESTIONS_ACTION,
          })
        );
        if (action.payload.filter === null) {
          next(
            apiRequest({
              method: 'POST',
              url: GET_MY_DENIED_QUESTIONS_URL,
              feature: GET_MY_DENIED_QUESTIONS_ACTION,
              body: {
                filter: { questionState: QuestionStateTypes.denied },
              },
            })
          );
        } else {
          next(
            apiRequest({
              method: 'POST',
              url: GET_MY_DENIED_QUESTIONS_URL,
              feature: GET_MY_DENIED_QUESTIONS_ACTION,
              body: action.payload,
            })
          );
        }

        break;
      case `${GET_MY_DENIED_QUESTIONS_ACTION} ${API_SUCCESS}`:
        next(
          setLoader({
            state: false,
            feature: GET_MY_DENIED_QUESTIONS_ACTION,
          })
        );

        next(
          setData({
            data: {
              myDeniedQuestions: [...action.payload.questions],
            },
            feature: GET_MY_DENIED_QUESTIONS_ACTION,
          })
        );

        next(
          setFilter({
            ...action.payload.filter,
            feature: GET_MY_DENIED_QUESTIONS_ACTION,
          })
        );
        break;
      case `${GET_MY_DENIED_QUESTIONS_ACTION} ${API_ERROR}`:
        next(
          setLoader({
            state: false,
            feature: GET_MY_DENIED_QUESTIONS_ACTION,
          })
        );
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_MY_DENIED_QUESTIONS_ACTION_ERROR_MSG,
            feature: GET_MY_DENIED_QUESTIONS_ACTION,
          })
        );
        break;
      default:
        break;
    }
  };
