const QuestionTypes = Object.freeze({
  newType: 'NEW_TYPE',
  classic: 'CLASSIC',
});

const getQuestionTypeFromString = (value) => {
  switch (value) {
    case 'NEW_TYPE':
      return QuestionTypes.newType;
    case 'CLASSIC':
      return QuestionTypes.classic;
    default:
      return null;
  }
};

export { QuestionTypes, getQuestionTypeFromString };
