/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Argon Dashboard 2 PRO MUI are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Components
import ArgonBox from 'components/ArgonBox';
import HomePage from 'features/home';
import NewQuestionPage from 'features/question/NewQuestionPage';
import { LanguageConstants, RouteConstants } from 'constants';
import SigninPage from 'features/signin';
import RequireAuth from 'hocs/RequireAuth';
import MyWaitingApproveQuestions from 'features/question/MyWaitingApproveQuestions';
import EditQuestionPage from 'features/question/EditQuestionPage';
import MyApprovedQuestions from 'features/question/MyApprovedQuestions';
import MyDeniedQuestions from 'features/question/MyDeniedQuestions';
import WaitingApproveQuestions from 'features/question/WaitingApproveQuestions';
import ApprovedQuestions from 'features/question/ApprovedQuestions';
import DeniedQuestions from 'features/question/DeniedQuestions';

const defaultRoutes = [
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    showInSidenav: false,
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-shop"
      />
    ),
    collapse: [
      {
        name: 'Home',
        key: 'home',
        route: RouteConstants.HOME,
        component: (
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        ),
        showInSidenav: false,
      },
      {
        name: 'Signin',
        key: 'signin',
        route: RouteConstants.SIGNIN,
        component: <SigninPage />,
        showInSidenav: false,
      },
    ],
  },
  { type: 'title', title: '', key: 'title-pages' },
  {
    type: 'collapse',
    name: LanguageConstants.OWN_QUESTIONS,
    key: 'own-questions',
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-ungroup"
      />
    ),
    collapse: [
      {
        name: LanguageConstants.NEW_QUESTION,
        key: 'new-question',
        route: RouteConstants.NEW_QUESTION,
        component: (
          <RequireAuth redirection={RouteConstants.NEW_QUESTION}>
            <NewQuestionPage />
          </RequireAuth>
        ),
      },
      {
        name: LanguageConstants.EDIT_QUESTION,
        key: 'own-edit-question',
        route: RouteConstants.OWN_EDIT_QUESTION,
        showInSidenav: false,
        component: (
          <RequireAuth redirection={RouteConstants.OWN_EDIT_QUESTION}>
            <EditQuestionPage route={RouteConstants.OWN_EDIT_QUESTION} />
          </RequireAuth>
        ),
      },
      {
        name: LanguageConstants.MY_WAITING_APPROVE_QUESTIONS,
        key: 'my-waiting-approve-questions',
        route: RouteConstants.MY_WAITING_APPROVE_QUESTIONS,
        component: (
          <RequireAuth
            redirection={RouteConstants.MY_WAITING_APPROVE_QUESTIONS}
          >
            <MyWaitingApproveQuestions />
          </RequireAuth>
        ),
      },
      {
        name: LanguageConstants.MY_APPROVED_QUESTIONS,
        key: 'my-approved-questions',
        route: RouteConstants.MY_APPROVED_QUESTIONS,
        component: (
          <RequireAuth redirection={RouteConstants.MY_APPROVED_QUESTIONS}>
            <MyApprovedQuestions />
          </RequireAuth>
        ),
      },
      {
        name: LanguageConstants.MY_DENIED_QUESTIONS,
        key: 'my-denied-questions',
        route: RouteConstants.MY_DENIED_QUESTIONS,
        component: (
          <RequireAuth redirection={RouteConstants.MY_DENIED_QUESTIONS}>
            <MyDeniedQuestions />
          </RequireAuth>
        ),
      },
    ],
  },
];

const approverRoutes = [
  {
    type: 'collapse',
    name: LanguageConstants.OTHER_QUESTIONS,
    key: 'other-questions',
    icon: (
      <ArgonBox
        component="i"
        color="info"
        fontSize="14px"
        className="ni ni-collection"
      />
    ),
    collapse: [
      {
        name: LanguageConstants.EDIT_QUESTION,
        key: 'other-edit-question',
        route: RouteConstants.OTHER_EDIT_QUESTION,
        showInSidenav: false,
        component: (
          <RequireAuth redirection={RouteConstants.OTHER_EDIT_QUESTION}>
            <EditQuestionPage route={RouteConstants.OTHER_EDIT_QUESTION} />
          </RequireAuth>
        ),
      },
      {
        name: LanguageConstants.WAITING_APPROVE_QUESTIONS,
        key: 'waiting-approve-questions',
        route: '/other-questions/waiting-approve-questions',
        component: <WaitingApproveQuestions />,
      },
      {
        name: LanguageConstants.APPROVED_QUESTIONS,
        key: 'approved-questions',
        route: '/other-questions/approved-questions',
        component: <ApprovedQuestions />,
      },
      {
        name: LanguageConstants.DENIED_QUESTIONS,
        key: 'denied-questions',
        route: '/other-questions/denied-questions',
        component: <DeniedQuestions />,
      },
    ],
  },
];

const routes = (userType) => {
  if (userType === 'ADMIN' || userType === 'EDICT') {
    return [...defaultRoutes, ...approverRoutes];
  }

  return [...defaultRoutes];
};

export default routes;
