/* eslint-disable no-case-declarations */
import { MessageTypes } from 'enumerations';
import {
  setRedirect,
  setLoader,
  // setFilter,
  setBackroute,
} from 'redux/actions/ui';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setNotification } from 'redux/actions/notifications';
import { setMessage } from 'redux/actions/message';
import {
  EDIT_QUESTION_FEATURE,
  EDIT_QUESTION_PAGE_START,
  SEND_APPROVE_ACTION,
  APPROVE_QUESTION_ACTION,
  DENY_QUESTION_ACTION,
  SAVE_QUESTION_ACTION,
  SAVE_APPROVER_NOTE_ACTION,
} from 'redux/actions/pages';
import { BASE_URL } from 'redux/actions/constants';
import { RouteConstants } from 'constants';

const SEND_APPROVE_ACTION_URL = `${BASE_URL}/api/question/update-question`;
const APPROVE_QUESTION_ACTION_URL = `${BASE_URL}/api/question/approve-question`;
const DENY_QUESTION_ACTION_URL = `${BASE_URL}/api/question/deny-question`;
const SAVE_QUESTION_ACTION_URL = `${BASE_URL}/api/question/update-question-by-approver`;
const SAVE_APPROVER_NOTE_ACTION_URL = `${BASE_URL}/api/question/update-question-by-approver`;

const SEND_APPROVE_ACTION_SUCCESS_MSG =
  'Soru başarılı bir şekilde onaya gönderildi.';
const SEND_APPROVE_ACTION_ERROR_MSG =
  'Onaya gönderme sırasında hata meydana geldi.';

const APPROVE_QUESTION_ACTION_SUCCESS_MSG =
  'Soru başarılı bir şekilde onaylandı.';
const APPROVE_QUESTION_ACTION_ERROR_MSG =
  'Onaylama sırasında hata meydana geldi.';

const DENY_QUESTION_ACTION_SUCCESS_MSG =
  'Başarılı bir şekilde revize talep edildi.';
const DENY_QUESTION_ACTION_ERROR_MSG =
  'Revize talebi sırasında hata meydana geldi.';

const SAVE_QUESTION_ACTION_SUCCESS_MSG =
  'Soru başarılı bir şekilde güncellendi.';
const SAVE_QUESTION_ACTION_ERROR_MSG =
  'Soru güncelleme sırasında hata meydana geldi.';

const SAVE_APPROVER_NOTE_ACTION_SUCCESS_MSG =
  'Onaycı notu başarılı bir şekilde kaydedildi.';
const SAVE_APPROVER_NOTE_ACTION_ERROR_MSG =
  'Not kaydetme sırasında hata meydana geldi.';

export const editQuestionMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui } = getState();

    switch (action.type) {
      case EDIT_QUESTION_PAGE_START:
        if (
          ui &&
          ui.redirectTo &&
          (ui.redirectTo === RouteConstants.OWN_EDIT_QUESTION ||
            ui.redirectTo === RouteConstants.OTHER_EDIT_QUESTION)
        ) {
          next(setRedirect({ to: '', feature: EDIT_QUESTION_FEATURE }));
        }

        next(
          setBackroute({
            ...ui.backRoute,
            questionId: action.payload.questionId,
            feature: EDIT_QUESTION_FEATURE,
          })
        );
        break;
      case SEND_APPROVE_ACTION:
        next(setLoader({ state: true, feature: SEND_APPROVE_ACTION }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SEND_APPROVE_ACTION_URL,
            feature: SEND_APPROVE_ACTION,
          })
        );
        break;
      case `${SEND_APPROVE_ACTION} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SEND_APPROVE_ACTION }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SEND_APPROVE_ACTION_SUCCESS_MSG,
            feature: SEND_APPROVE_ACTION,
          })
        );

        if (ui.backRoute?.questionId === action.payload.question.id) {
          next(
            setRedirect({
              to: ui.backRoute?.route ?? '',
              feature: EDIT_QUESTION_FEATURE,
            })
          );
        }

        break;
      case `${SEND_APPROVE_ACTION} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SEND_APPROVE_ACTION }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: SEND_APPROVE_ACTION_ERROR_MSG,
            feature: SEND_APPROVE_ACTION,
          })
        );
        break;
      case APPROVE_QUESTION_ACTION:
        next(setLoader({ state: true, feature: APPROVE_QUESTION_ACTION }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: APPROVE_QUESTION_ACTION_URL,
            feature: APPROVE_QUESTION_ACTION,
          })
        );
        break;
      case `${APPROVE_QUESTION_ACTION} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: APPROVE_QUESTION_ACTION }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: APPROVE_QUESTION_ACTION_SUCCESS_MSG,
            feature: APPROVE_QUESTION_ACTION,
          })
        );

        if (ui.backRoute?.questionId === action.payload.question.id) {
          next(
            setRedirect({
              to: ui.backRoute?.route ?? '',
              feature: EDIT_QUESTION_FEATURE,
            })
          );
        }

        break;
      case `${APPROVE_QUESTION_ACTION} ${API_ERROR}`:
        next(setLoader({ state: false, feature: APPROVE_QUESTION_ACTION }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: APPROVE_QUESTION_ACTION_ERROR_MSG,
            feature: APPROVE_QUESTION_ACTION,
          })
        );
        break;
      case DENY_QUESTION_ACTION:
        next(setLoader({ state: true, feature: DENY_QUESTION_ACTION }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: DENY_QUESTION_ACTION_URL,
            feature: DENY_QUESTION_ACTION,
          })
        );
        break;
      case `${DENY_QUESTION_ACTION} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: DENY_QUESTION_ACTION }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: DENY_QUESTION_ACTION_SUCCESS_MSG,
            feature: DENY_QUESTION_ACTION,
          })
        );

        if (ui.backRoute?.questionId === action.payload.question.id) {
          next(
            setRedirect({
              to: ui.backRoute?.route ?? '',
              feature: EDIT_QUESTION_FEATURE,
            })
          );
        }

        break;
      case `${DENY_QUESTION_ACTION} ${API_ERROR}`:
        next(setLoader({ state: false, feature: DENY_QUESTION_ACTION }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: DENY_QUESTION_ACTION_ERROR_MSG,
            feature: DENY_QUESTION_ACTION,
          })
        );
        break;
      case SAVE_QUESTION_ACTION:
        next(setLoader({ state: true, feature: SAVE_QUESTION_ACTION }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SAVE_QUESTION_ACTION_URL,
            feature: SAVE_QUESTION_ACTION,
          })
        );
        break;
      case `${SAVE_QUESTION_ACTION} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SAVE_QUESTION_ACTION }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_QUESTION_ACTION_SUCCESS_MSG,
            feature: SAVE_QUESTION_ACTION,
          })
        );

        if (ui.backRoute?.questionId === action.payload.question.id) {
          next(
            setRedirect({
              to: ui.backRoute?.route ?? '',
              feature: EDIT_QUESTION_FEATURE,
            })
          );
        }

        break;
      case `${SAVE_QUESTION_ACTION} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SAVE_QUESTION_ACTION }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: SAVE_QUESTION_ACTION_ERROR_MSG,
            feature: SAVE_QUESTION_ACTION,
          })
        );
        break;
      case SAVE_APPROVER_NOTE_ACTION:
        next(setLoader({ state: true, feature: SAVE_APPROVER_NOTE_ACTION }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SAVE_APPROVER_NOTE_ACTION_URL,
            feature: SAVE_APPROVER_NOTE_ACTION,
          })
        );
        break;
      case `${SAVE_APPROVER_NOTE_ACTION} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SAVE_APPROVER_NOTE_ACTION }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: SAVE_APPROVER_NOTE_ACTION_SUCCESS_MSG,
            feature: SAVE_APPROVER_NOTE_ACTION,
          })
        );

        // if (ui.backRoute?.questionId === action.payload.question.id) {
        //   next(
        //     setRedirect({
        //       to: ui.backRoute?.route ?? '',
        //       feature: EDIT_QUESTION_FEATURE,
        //     })
        //   );
        // }

        break;
      case `${SAVE_APPROVER_NOTE_ACTION} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SAVE_APPROVER_NOTE_ACTION }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: SAVE_APPROVER_NOTE_ACTION_ERROR_MSG,
            feature: SAVE_APPROVER_NOTE_ACTION,
          })
        );
        break;
      default:
        break;
    }
  };
