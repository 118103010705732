import { PAGES } from 'redux/actions/pages';

// feature name
export const NAVBAR = `${PAGES} [Navbar]`;

// action types
export const SIGNOUT_ACTION = `${NAVBAR} SIGNOUT`;

// action creators
export const signoutAction = () => ({
  type: SIGNOUT_ACTION,
});
