/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Authentication layout components
import BasicLayout from './components/BasicLayout';
import bgImage from 'assets/images/kazanirim/aydinlatma-metni.jpg';
import './_kvkk-style.css';

function Kvkk() {
  return (
    <BasicLayout
      image={bgImage}
      button={{ variant: 'gradient', color: 'dark' }}
    >
      <Card
        p={5}
        sx={{
          height: '90vh',
          overflowY: 'scroll',
        }}
      >
        <ArgonBox p={5} my={3} textAlign="center">
          <ArgonTypography
            variant="h4"
            color="dark"
            fontWeight="bold"
            sx={{ mb: 4 }}
          >
            KVKK AYDINLATMA METNİ
          </ArgonTypography>
          <ArgonTypography variant="h5" color="text" sx={{ mb: 3 }}>
            Edict Digital 6698 Sayılı Kişisel Verilerin Korunması Kanunu
            Kapsamındaki Aydınlatma Metni
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            1. Veri Sorumlusunun Kimliği
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması
            Kanununun 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine
            Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ (Kanun)
            kapsamında veri sorumlusu sıfatıyla Edict Digital Bilgi
            Teknolojileri A.Ş. tarafından hazırlanmıştır.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            2. Kişisel Verilerinizin İşlenme Amacı
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Edict Digital Bilgi Teknolojileri A.Ş., sözlü, yazılı ya da
            elektronik ortamda edinilmiş kişisel verileri 6698 Sayılı Kanunu’nun
            5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve
            amaçları kapsamında işbu Aydınlatma Metninde belirtilen amaçlarla
            işleyebilmekte ve aktarılabilmektedir. Toplanan kişisel veriler,
            Kanun tarafından öngörülen temel ilkelere uygun olarak, aşağıda
            belirtilen amaçlar doğrultusunda işlenebilir ve taraflara
            aktarılabilir. Sunulan hizmeti sağlamak ve geliştirmek, kullanıcı
            deneyimlerini kişiselleştirmek, kullanıcı ile iletişim kurmak,
            aldığınız hizmeti anlamak ve geliştirmek, Edict Digital ortaklık
            düzenlemelerine katılımınızı sağlamaktır.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            3. Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Kişisel verileriniz Edict Digital faaliyetlerini yürütmek amacıyla
            farklı kanallarla hukuki sebeplerine dayanılarak toplanmaktadır.
            Edict Digital'in veri sahibinden aldığı kişisel veriler aşağıda
            listelenmiştir.
            <ul className="kvkk-list">
              <li className="kvkk-list-item">
                a. İletişim ve profil bilgileri: Adınız, e-posta adresiniz,
                mesleğiniz ve doğum tarihiniz dahil kullanıcı bilgileri,
                kaydolduktan sonra profilinizde takma adınız, konumunuz (şehir)
                ve diğer kişisel veya demografik bilgiler gibi ek bilgiler.
              </li>
              <li className="kvkk-list-item">
                b. Üçüncü taraf entegre hizmetlerden hesap ve kimlik doğrulama
                bilgileri: Facebook, Google ve benzeri tek oturum açma (single
                sign on) hizmeti gibi üçüncü taraf bir sosyal ağ veya entegre
                hizmet aracılığıyla kaydolmaya veya başka bir şekilde erişim
                izni vermeye karar verirseniz, Edict Digital'de “Entegre Hizmet”
                hesabınızla ilişkilendirilmiş kişisel bilgileri edinir.
              </li>
              <li className="kvkk-list-item">
                c. Konum bilgisi: Bölgeniz için size özel eğitim deneyimleri
                sunmak amacıyla konumunuz (ülkeniz, iliniz gibi) hakkında bilgi
                toplayabilir ve kullanabilir veya IP adresinize göre yaklaşık
                konumunuzu çıkarabiliriz, ancak tam olarak coğrafi konumunuz
                alınmaz. Herhangi bir konum bilgisi verilmesini önlemek için
                bilgisayarınızdaki veya mobil cihazınızdaki ayarları
                değiştirebilirsiniz.
              </li>
              <li className="kvkk-list-item">
                d. Hizmetimizi kullanımınızla ilgili bilgiler: Hizmetimizi
                kullanımınızla ilgili görüntülediğiniz sayfalar,
                görüntülediğiniz video sayısı ve bir sayfada harcanan süre gibi
                kullanım bilgilerini toplayabiliriz. Bu, sizin için en uygun
                eğitim deneyimlerini daha iyi uyarlamamızı sağlar.
              </li>
              <li className="kvkk-list-item">
                e. Kullanım ve cihaz bilgileri: Kullanıcılarımıza
                kişiselleştirilmiş ve yüksek kaliteli deneyim sağlamak için, web
                sitemizi ziyaret ettiğinizde, e-postalarımızı okuduğumuzda,
                hizmetimizi kullandığımızda veya başka bir şekilde bizimle
                etkileşime girdiğinde tarayıcınızdan veya cihazınızdan belirli
                teknik bilgileri otomatik olarak kaydeden çeşitli teknolojiler
                kullanabiliriz. Bu bilgiler genellikle çerezler, web
                işaretçileri, Flash nesneleri, günlük dosyaları ve benzer
                teknolojiler (topluca “izleme teknolojileri”) dahil olmak üzere
                çeşitli izleme teknolojileri aracılığıyla toplanır.
              </li>
            </ul>
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            4. Kişisel Verilerinizin Aktarıldığı Taraflar ve Aktarım Amaçları
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Toplanan kişisel verileriniz, Kanun tarafından öngörülen temel
            ilkelere uygun olarak ve Kanun’un 8. ve 9. maddelerinde belirtilen
            kişisel veri işleme şartları ve amaçları dahilinde, Edict Digital
            tarafından aşağıda yer alan amaçlar doğrultusunda iş ortaklarımıza,
            tedarikçilerimize, kanunen yetkili kamu kurumları ve kanunen yetkili
            özel kurumlara aktarılabilmektedir:
            <ul className="kvkk-list">
              <li className="kvkk-list-item">
                1. İş süreçlerimizin mevzuata uygun yürütülmesi ile hukuk
                işlerinin takibi ve yürütülmesi amacıyla avukatlar, denetçiler
                ve vergi danışmanlarına,
              </li>
              <li className="kvkk-list-item">
                2. Yetkili kurum ve kuruluşlara bilgi verilmesi amacıyla
                düzenleyici ve denetleyici kurumlar, mahkeme ve icra
                müdürlükleri gibi sair resmî kurumlara,
              </li>
              <li className="kvkk-list-item">
                3. Taleplerinizin yerine getirilmesi amacıyla tarafınızca yetki
                verilmiş olan vekil ve temsilcilerinize,
              </li>
              <li className="kvkk-list-item">
                4. Yayınların hedeflenen kitlelere ulaşmasını sağlamak amacıyla
                sosyal medya platformlarına,
              </li>
              <li className="kvkk-list-item">
                5. Faaliyetlerimizin devamlılığı ve organizasyon
                gerekliliklerini sağlamak amacıyla hizmet aldığımız üçüncü kişi
                şirketlere ve iş ortaklarımıza aktarılabilmektedir.
              </li>
            </ul>
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            5. Kişisel Veri Sahibi Olarak Kanun'un 11. Maddesinde Sayılan
            Hakları
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki
            haklara sahipsiniz:
            <ul className="kvkk-list">
              <li className="kvkk-list-item">
                1. Kişisel verilerinizin işlenip işlenmediğini öğrenme,
              </li>
              <li className="kvkk-list-item">
                2. Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li className="kvkk-list-item">
                3. Kişisel verilerinizin işlenme amacını ve bunların amacına
                uygun kullanılıp kullanılmadığını öğrenme,
              </li>
              <li className="kvkk-list-item">
                4. Yurt içinde veya yurt dışında kişisel verilerinizin
                aktarıldığı üçüncü̈ kişileri bilme,
              </li>
              <li className="kvkk-list-item">
                5. Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan
                işlemin kişisel verilerinizin aktarıldığı üçüncü̈ kişilere
                bildirilmesini isteme,
              </li>
              <li className="kvkk-list-item">
                6. Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak
                işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
                edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                verilerinizin aktarıldığı üçüncü̈ kişilere bildirilmesini isteme,
              </li>
              <li className="kvkk-list-item">
                7. İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkması
                durumunda buna itiraz etme,
              </li>
              <li className="kvkk-list-item">
                8. Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep
                etme
              </li>
            </ul>
            Haklarınıza yönelik başvurularınızı edictdigital@gmail.com adresine
            iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en
            geç otuz gün içinde ücretsiz olarak başvurularınız
            sonuçlandırılacaktır; ancak ayrıca bir maliyet doğması halinde Edict
            Digital'in Kişisel Verileri Koruma Kurulunca belirlenecek tarifeye
            göre tarafınızdan ücret talep edebilme hakkı saklıdır.
          </ArgonTypography>
        </ArgonBox>
      </Card>
    </BasicLayout>
  );
}

export default Kvkk;
