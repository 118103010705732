/* eslint-disable no-case-declarations */
import { MessageTypes } from 'enumerations';
import { setRedirect, setLoader } from 'redux/actions/ui';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setNotification } from 'redux/actions/notifications';
import { setMessage } from 'redux/actions/message';
import {
  NEW_QUESTION_FEATURE,
  NEW_QUESTION_PAGE_START,
  INSERT_NEW_QUESTION_ACTION,
} from 'redux/actions/pages';
import { BASE_URL } from 'redux/actions/constants';
import { RouteConstants } from 'constants';

const INSERT_NEW_QUESTION_ACTION_URL = `${BASE_URL}/api/question/new-question`;

const INSERT_NEW_QUESTION_ACTION_SUCCESS_MSG =
  'Soru başarılı bir şekilde eklendi.';
const INSERT_NEW_QUESTION_ACTION_ERROR_MSG =
  'Soru ekleme sırasında hata meydana geldi.';

export const newQuestionMiddleware =
  ({ getState /* dispatch */ }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui } = getState();

    switch (action.type) {
      case NEW_QUESTION_PAGE_START:
        if (
          ui &&
          ui.redirectTo &&
          ui.redirectTo === RouteConstants.NEW_QUESTION
        ) {
          next(setRedirect({ to: '', feature: NEW_QUESTION_FEATURE }));
        }
        break;
      case INSERT_NEW_QUESTION_ACTION:
        next(setLoader({ state: true, feature: INSERT_NEW_QUESTION_ACTION }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: INSERT_NEW_QUESTION_ACTION_URL,
            feature: INSERT_NEW_QUESTION_ACTION,
          })
        );
        break;
      case `${INSERT_NEW_QUESTION_ACTION} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: INSERT_NEW_QUESTION_ACTION }));
        next(
          setNotification({
            type: MessageTypes.success,
            message: INSERT_NEW_QUESTION_ACTION_SUCCESS_MSG,
            feature: INSERT_NEW_QUESTION_ACTION,
          })
        );
        break;
      case `${INSERT_NEW_QUESTION_ACTION} ${API_ERROR}`:
        next(setLoader({ state: false, feature: INSERT_NEW_QUESTION_ACTION }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent: INSERT_NEW_QUESTION_ACTION_ERROR_MSG,
            feature: INSERT_NEW_QUESTION_ACTION,
          })
        );
        break;
      default:
        break;
    }
  };
