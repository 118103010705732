import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';

import QuestionInfo from '../QuestionInfo';
import QuestionSpecificInfo from '../QuestionSpecificInfo';
import { Form, Formik } from 'formik';
import questionFormInitialValues from './schemas/questionFormInitialValues';
import questionFormValidations from './schemas/questionFormValidations';
import questionForm from './schemas/questionForm';
import Swal from 'sweetalert2';
import ImageKit from 'imagekit-javascript';
import { connect } from 'react-redux';
import { saveApproverNoteAction } from 'redux/actions/pages';
import { StringHelpers } from 'helpers';
import { LanguageConstants } from 'constants';
import { Card, Dialog, DialogContent } from '@mui/material';
import ArgonInput from 'components/ArgonInput';
import SendApproveNewQuestionButton from '../SendApproveNewQuestionButton';
import { RouteConstants } from 'constants';
import SendApproveButton from '../SendApproveButton';
import SaveButton from '../SaveButton';
import DenyButton from '../DenyButton';
import ApproveButton from '../ApproveButton';

const QuestionForms = ({
  token,
  title,
  description,
  question,
  topics,
  subtopics,
  backRoute,
  saveApproverNoteAction,
}) => {
  const navigate = useNavigate();

  const formikRef = useRef();

  const [openApproverNoteDialog, setOpenApprovedNoteDialog] = useState(false);
  const [approverNote, setApproverNote] = useState('');

  const [questionImageUrl, setQuestionImageUrl] = useState('');
  const [questionImageUploading, setQuestionImageUploading] = useState(false);
  const [validateImageUrl, setValidateImageUrl] = useState(false);
  const [solutionImageUrl, setSolutionImageUrl] = useState('');
  const [solutionImageUploading, setSolutionImageUploading] = useState(false);
  const [fileInputKey, setFileInputKey] = useState('');
  const [solutionFileInputKey, setSolutionFileInputKey] = useState('');

  useEffect(() => {
    if (
      question !== null &&
      question !== undefined &&
      question.id > 0 &&
      question.approverNote !== null &&
      question.approverNote !== undefined &&
      question.approverNote !== ''
    ) {
      setApproverNote(question.approverNote);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if question not null initialize formik values with question
    if (question !== null && question !== undefined && question.id > 0) {
      setQuestionImageUrl(question.questionUrl);
      setSolutionImageUrl(question.imageAnswerUrl);
    }
  }, [question]);

  const getInitialValues = () => {
    if (question !== null && question !== undefined && question.id > 0) {
      // setQuestionImageUrl(question.questionUrl);
      // setSolutionImageUrl(question.imageAnswerUrl);
      return {
        usage: question.usage
          ? {
              value: question.usage,
              label: StringHelpers.capitalizeWordsString(
                LanguageConstants[question.usage]
              ),
            }
          : null,
        topics: question.topics
          ? question.topics.map((topic) => {
              let label = topic;
              if (topics?.length > 0) {
                const currentTopic = topics.find((t) => t.id === topic);
                if (currentTopic) {
                  label =
                    currentTopic.code +
                    StringHelpers.capitalizeWordsString(currentTopic.name);
                }
              }
              return { value: topic, label };
            })
          : [],
        subtopics: question.subtopics
          ? question.subtopics.map((subtopic) => {
              let label = subtopic;
              if (subtopics?.length > 0) {
                const currentSubtopic = subtopics.find(
                  (s) => s.id === subtopic
                );
                if (currentSubtopic) {
                  label = StringHelpers.capitalizeWordsString(
                    currentSubtopic.name
                  );
                }
              }
              return { value: subtopic, label };
            })
          : [],
        questionType: question.questionType
          ? {
              value: question.questionType,
              label: StringHelpers.capitalizeWordsString(
                LanguageConstants[question.questionType]
              ),
            }
          : null,
        difficulty: question.difficulty
          ? {
              value: question.difficulty,
              label: question.difficulty,
            }
          : null,
        correctAnswer: question.questionType
          ? {
              value: question.correctAnswer,
              label: question.correctAnswer,
            }
          : null,
        solutionVideoUrl: question.videoAnswerUrl ?? '',
      };
    }

    return questionFormInitialValues;
  };

  const onQuestionImageChange = (event) => {
    setQuestionImageUrl('');
    setFileInputKey(uuidv4());
    if (token === null || token === undefined || token === '') {
      return;
    }

    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 1) {
        Swal.fire({
          icon: 'error',
          title: 'Dosya Boyutu Hatası',
          text: "Yüklemeye çalıştığınız dosyanın boyutu 1MB'in üzerindedir. Lütfen https://squoosh.app kullanarak dosya boyunu küçültünüz.",
          showCancelButton: false,
          confirmButtonText: 'Tamam',
        }).then(() => {
          /* Do nothing */
        });
      } else {
        setQuestionImageUploading(true);

        const imagekit = new ImageKit({
          publicKey: 'public_xsnJyvrAhBB5shKT6br7X1itaMY=',
          urlEndpoint: 'https://ik.imagekit.io/mvnw0kw1mga/kazanirim-mat-lgs',
          authenticationEndpoint: `https://kazanirim.com/api/upload/imagekit-endpoint/${token}`,
        });

        imagekit.upload(
          {
            file,
            fileName: `${uuidv4()}.${
              file?.name?.split('.')?.splice(-1)[0] ?? ''
            }`,
            folder: '/kazanirim/questions/',
          },
          (err, result) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log('Error on imagekit.upload');
              // eslint-disable-next-line no-console
              console.log(err);
              return;
            }

            setQuestionImageUrl(result.url);
            setQuestionImageUploading(false);
          }
        );
      }
    }
  };

  const onSolutionImageChange = (event) => {
    setSolutionImageUrl('');
    setSolutionFileInputKey(uuidv4());

    if (token === null || token === undefined || token === '') {
      return;
    }

    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 1) {
        Swal.fire({
          icon: 'error',
          title: 'Dosya Boyutu Hatası',
          text: "Yüklemeye çalıştığınız dosyanın boyutu 1MB'in üzerindedir. Lütfen https://squoosh.app kullanarak dosya boyunu küçültünüz.",
          showCancelButton: false,
          confirmButtonText: 'Tamam',
        }).then(() => {
          /* Do nothing */
        });
      } else {
        setSolutionImageUploading(true);

        const imagekit = new ImageKit({
          publicKey: 'public_xsnJyvrAhBB5shKT6br7X1itaMY=',
          urlEndpoint: 'https://ik.imagekit.io/mvnw0kw1mga/kazanirim-mat-lgs',
          authenticationEndpoint: `https://kazanirim.com/api/upload/imagekit-endpoint/${token}`,
        });

        imagekit.upload(
          {
            file,
            fileName: `${uuidv4()}.${
              file?.name?.split('.')?.splice(-1)[0] ?? ''
            }`,
            folder: '/kazanirim/solutions/',
          },
          (err, result) => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log('Error on imagekit.upload');
              // eslint-disable-next-line no-console
              console.log(err);
              return;
            }

            setSolutionImageUrl(result.url);
            setSolutionImageUploading(false);
          }
        );
      }
    }
  };
  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="sm"
        open={openApproverNoteDialog}
      >
        <DialogContent>
          <ArgonBox m={2}>
            <ArgonTypography
              component="label"
              variant="h5"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Onaycı Notu
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox m={2}>
            <ArgonInput
              multiline
              rows={5}
              placeholder="Lütfen notunuzu giriniz."
              value={approverNote}
              onChange={(e) => setApproverNote(e.target.value)}
            />
          </ArgonBox>
          <ArgonBox m={2} mt={4} display="flex" justifyContent="flex-end">
            <ArgonBox mr={2}>
              <ArgonButton
                variant="text"
                color="dark"
                onClick={() => {
                  setOpenApprovedNoteDialog(false);
                }}
              >
                Vazgeç
              </ArgonButton>
            </ArgonBox>
            <ArgonButton
              variant="contained"
              color="secondary"
              onClick={() => {
                const {
                  id,
                  questionUrl,
                  usage,
                  questionType,
                  difficulty,
                  correctAnswer,
                  videoAnswerUrl,
                  imageAnswerUrl,
                  subTopics,
                } = question;

                const submittedQuestion = {
                  id,
                  questionUrl,
                  usage,
                  questionType,
                  difficulty,
                  correctAnswer,
                  videoAnswerUrl,
                  imageAnswerUrl,
                  subTopics,
                  approverNote,
                };
                saveApproverNoteAction({
                  question: submittedQuestion,
                });

                setOpenApprovedNoteDialog(false);
              }}
            >
              Kaydet
            </ArgonButton>
          </ArgonBox>
        </DialogContent>
      </Dialog>
      <Formik
        // enableReinitialize
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={questionFormValidations.validation}
        onSubmit={() => {}}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          resetForm,
          handleSubmit,
          setSubmitting,
        }) => {
          return (
            <Form id={questionForm.formId} autoComplete="off">
              <ArgonBox my={3}>
                <ArgonBox mb={3} position="relative">
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <ArgonTypography
                        variant="h4"
                        color="white"
                        fontWeight="medium"
                      >
                        {title}
                      </ArgonTypography>
                      <ArgonBox mt={1} mb={2}>
                        <ArgonTypography variant="body2" color="white">
                          {description}
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <ArgonBox display="flex" justifyContent="flex-end">
                        <ArgonBox>
                          <ArgonButton
                            variant="contained"
                            color="light"
                            onClick={() => {
                              if (
                                question !== null &&
                                question !== undefined &&
                                question.id > 0
                              ) {
                                if (
                                  backRoute !== undefined &&
                                  backRoute !== null &&
                                  backRoute.route &&
                                  backRoute.route !== '' &&
                                  backRoute.questionId === question.id
                                ) {
                                  navigate(backRoute.route);
                                }
                              } else {
                                setValidateImageUrl(false);
                                setQuestionImageUrl('');
                                setSolutionImageUrl('');
                                resetForm();
                              }
                            }}
                          >
                            {question !== null &&
                            question !== undefined &&
                            question.id > 0
                              ? 'Vazgeç'
                              : 'Temizle'}
                          </ArgonButton>
                        </ArgonBox>
                        {question === null ||
                        question === undefined ||
                        !(question?.id > 0) ? (
                          <SendApproveNewQuestionButton
                            setValidateImageUrl={setValidateImageUrl}
                            handleSubmit={handleSubmit}
                            values={values}
                            errors={errors}
                            questionImageUrl={questionImageUrl}
                            solutionImageUrl={solutionImageUrl}
                            resetSecondSection={() => {
                              setSubmitting(false);
                              setFieldValue(
                                questionForm.formField.correctAnswer.name,
                                null
                              );
                              setFieldTouched(
                                questionForm.formField.correctAnswer.name,
                                false
                              );
                              setFieldValue(
                                questionForm.formField.solutionVideoUrl.name,
                                ''
                              );
                              setFieldTouched(
                                questionForm.formField.solutionVideoUrl.name,
                                false
                              );
                              setQuestionImageUrl('');
                              setValidateImageUrl(false);
                              setSolutionImageUrl('');
                            }}
                          />
                        ) : backRoute?.questionId === question?.id &&
                          [
                            RouteConstants.MY_WAITING_APPROVE_QUESTIONS,
                            RouteConstants.MY_DENIED_QUESTIONS,
                          ].includes(backRoute?.route) ? (
                          <SendApproveButton
                            setValidateImageUrl={setValidateImageUrl}
                            handleSubmit={handleSubmit}
                            values={values}
                            errors={errors}
                            questionImageUrl={questionImageUrl}
                            solutionImageUrl={solutionImageUrl}
                            questionId={question.id}
                            approverNote={question.approverNote}
                          />
                        ) : null}

                        {question !== null &&
                        question !== undefined &&
                        question?.id > 0 &&
                        backRoute?.questionId === question?.id &&
                        [
                          RouteConstants.APPROVED_QUESTIONS,
                          RouteConstants.DENIED_QUESTIONS,
                        ].includes(backRoute?.route) ? (
                          <SaveButton
                            setValidateImageUrl={setValidateImageUrl}
                            handleSubmit={handleSubmit}
                            values={values}
                            errors={errors}
                            questionImageUrl={questionImageUrl}
                            solutionImageUrl={solutionImageUrl}
                            questionId={question.id}
                            approverNote={question.approverNote}
                          />
                        ) : null}

                        {question !== null &&
                        question !== undefined &&
                        question?.id > 0 &&
                        backRoute?.questionId === question?.id &&
                        [
                          RouteConstants.WAITING_APPROVE_QUESTIONS,
                          RouteConstants.APPROVED_QUESTIONS,
                        ].includes(backRoute?.route) ? (
                          <DenyButton
                            setValidateImageUrl={setValidateImageUrl}
                            handleSubmit={handleSubmit}
                            values={values}
                            errors={errors}
                            questionImageUrl={questionImageUrl}
                            solutionImageUrl={solutionImageUrl}
                            questionId={question.id}
                            denyNote={question.approverNote}
                          />
                        ) : null}

                        {question !== null &&
                        question !== undefined &&
                        question?.id > 0 &&
                        backRoute?.questionId === question?.id &&
                        [
                          RouteConstants.WAITING_APPROVE_QUESTIONS,
                          RouteConstants.DENIED_QUESTIONS,
                        ].includes(backRoute?.route) ? (
                          <ApproveButton
                            setValidateImageUrl={setValidateImageUrl}
                            handleSubmit={handleSubmit}
                            values={values}
                            errors={errors}
                            questionImageUrl={questionImageUrl}
                            solutionImageUrl={solutionImageUrl}
                            questionId={question.id}
                            approverNote={question.approverNote}
                          />
                        ) : null}
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
                {question !== null &&
                question !== undefined &&
                question?.id > 0 &&
                question?.approverNote !== null &&
                question?.approverNote !== undefined &&
                question?.approverNote !== '' ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <ArgonBox mb={3}>
                        <Card sx={{ overflow: 'visible' }}>
                          <ArgonBox p={3}>
                            <ArgonBox
                              display="flex"
                              justifyContent="space-between"
                            >
                              <ArgonTypography variant="h5">
                                Onaycı Notu
                              </ArgonTypography>
                              {question !== null &&
                              question !== undefined &&
                              question?.id > 0 &&
                              backRoute?.questionId === question?.id &&
                              [
                                RouteConstants.APPROVED_QUESTIONS,
                                RouteConstants.WAITING_APPROVE_QUESTIONS,
                                RouteConstants.DENIED_QUESTIONS,
                              ].includes(backRoute?.route) ? (
                                <ArgonButton
                                  variant="text"
                                  color="secondary"
                                  onClick={() =>
                                    setOpenApprovedNoteDialog(true)
                                  }
                                >
                                  Düzenle
                                </ArgonButton>
                              ) : null}
                            </ArgonBox>
                            <ArgonBox mt={1}>
                              <ArgonTypography variant="body2" color="dark">
                                {approverNote}
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonBox>
                        </Card>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <QuestionInfo
                      formData={{
                        values,
                        touched,
                        formField: questionForm.formField,
                        setFieldValue,
                        setFieldTouched,
                        handleBlur,
                        handleChange,
                        errors,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <QuestionSpecificInfo
                      formData={{
                        values,
                        touched,
                        formField: questionForm.formField,
                        setFieldValue,
                        setFieldTouched,
                        handleBlur,
                        handleChange,
                        errors,
                      }}
                      questionImageUploading={questionImageUploading}
                      questionImageUrl={questionImageUrl}
                      onQuestionImageChange={onQuestionImageChange}
                      onQuestionImageRemove={() => setQuestionImageUrl('')}
                      validateImageUrl={validateImageUrl}
                      solutionImageUploading={solutionImageUploading}
                      solutionImageUrl={solutionImageUrl}
                      onSolutionImageChange={onSolutionImageChange}
                      onSolutionImageRemove={() => setSolutionImageUrl('')}
                      fileInputKey={fileInputKey}
                      solutionFileInputKey={solutionFileInputKey}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

QuestionForms.defaultProps = {
  token: null,
  description: '',
  question: null,
  topics: [],
  subtopics: [],
  backRoute: null,
};

QuestionForms.propTypes = {
  token: PropTypes.string,
  saveApproverNoteAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  question: PropTypes.objectOf(PropTypes.any),
  topics: PropTypes.arrayOf(PropTypes.any),
  subtopics: PropTypes.arrayOf(PropTypes.any),
  backRoute: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  topics: state.data.topics,
  subtopics: state.data.subtopics,
  backRoute: state.ui.backRoute,
});

const mapDispatchToProps = {
  saveApproverNoteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionForms);

/**
 * Sayfadaki Butonlar:
 * own-questions/new-question : Temizle / Onaya Gönder
 * own-questions/my-waiting-approve-questions: Vazgeç / Onaya Gönder
 * own-questions/my-approved-question: ------------------- YOK ------------
 * own-questions/my-denied-questions: Vazgeç / Onaya Gönder
 * ***************************************************************** *
 * other-questions/waiting-approve-questions: Vazgeç / Reddet / Onayla
 * other-questions/approved-questions: Vazgeç / Reddet / Kaydet
 * other-questions/denied-questions: Vazgeç / Onayla / Kaydet
 * (Reddedilenlerde Notu düzelt ve kaydet gibi bir kısım olmalı)
 */
