const QuestionUsageTypes = Object.freeze({
  solving: 'SOLVING',
  testing: 'TESTING',
  pilotTest: 'PILOT_TEST',
});

const getQuestionUsageTypeFromString = (value) => {
  switch (value) {
    case 'SOLVING':
      return QuestionUsageTypes.solving;
    case 'TESTING':
      return QuestionUsageTypes.testing;
    case 'PILOT_TEST':
      return QuestionUsageTypes.pilotTest;
    default:
      return null;
  }
};

export { QuestionUsageTypes, getQuestionUsageTypeFromString };
