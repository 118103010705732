/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
// import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
// import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import { PageLayout } from 'features/page-layout';

// Authentication layout components
// import Footer from '../Footer';

// Argon Dashboard 2 PRO MUI page layout routes
// import pageRoutes from 'page.routes';

function BasicLayout({ button, image, children }) {
  return (
    <PageLayout>
      {/* <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: 'external',
          route: 'https://creative-tim.com/product/argon-dashboard-pro-mui',
          label: 'Buy Now',
          ...button,
        }}
      /> */}
      <ArgonBox
        display="grid"
        alignItems="center"
        width="100%"
        height="100vh"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <ArgonBox px={1} width="100%" mx="auto">
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              {children}
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
      {/* <Footer /> */}
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: '',
  description: '',
  button: { color: 'info' },
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  button: PropTypes.object,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
