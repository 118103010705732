/* eslint-disable no-case-declarations */
import { SET_DATA, REMOVE_DATA, CLEAR_DATA } from 'redux/actions/data';
// import { StateHelper } from "helpers/state.helper";

const initState = {};

export const dataReducer = (data = initState, action) => {
  switch (true) {
    case action.type.includes(SET_DATA):
      return { ...data, ...action.payload };

    case action.type.includes(REMOVE_DATA):
      const copyOfData = { ...data };
      delete copyOfData[action.payload.where];
      return copyOfData;

    case action.type.includes(CLEAR_DATA):
      return initState;

    default:
      return data;
  }
};
