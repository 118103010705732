import React from 'react';
import PropTypes from 'prop-types';

import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { connect } from 'react-redux';
import { sendApproveAction } from 'redux/actions/pages';

const SendApproveButton = ({
  setValidateImageUrl,
  handleSubmit,
  values,
  errors,
  questionImageUrl,
  solutionImageUrl,
  questionId,
  sendApproveAction,
  approverNote,
}) => {
  return (
    <ArgonBox ml={2}>
      <ArgonButton
        variant="contained"
        color="warning"
        type="button"
        onClick={() => {
          setValidateImageUrl(true);

          handleSubmit();

          if (Object.keys(errors).length > 0) {
            return;
          }

          if (
            questionImageUrl === null ||
            questionImageUrl === undefined ||
            questionImageUrl === ''
          ) {
            return;
          }

          const submittedQuestion = {
            id: questionId,
            questionUrl: questionImageUrl,
            usage: values?.usage?.value ?? null,
            questionType: values?.questionType?.value ?? null,
            difficulty: values?.difficulty?.value ?? 0,
            correctAnswer: values?.correctAnswer?.value ?? null,
            videoAnswerUrl: values?.solutionVideoUrl ?? null,
            imageAnswerUrl: solutionImageUrl,
            subTopics: values?.subtopics?.map((st) => st.value),
            approverNote,
          };

          sendApproveAction({
            question: submittedQuestion,
          });
        }}
      >
        Onaya Gönder
      </ArgonButton>
    </ArgonBox>
  );
};

SendApproveButton.defaultProps = {
  values: null,
  errors: null,
  questionImageUrl: '',
  solutionImageUrl: '',
  approverNote: '',
};

SendApproveButton.propTypes = {
  setValidateImageUrl: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  questionImageUrl: PropTypes.string,
  solutionImageUrl: PropTypes.string,
  questionId: PropTypes.number.isRequired,
  sendApproveAction: PropTypes.func.isRequired,
  approverNote: PropTypes.string,
};

const mapStateToProps = (_) => ({});

const mapDispatchToProps = {
  sendApproveAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendApproveButton);
