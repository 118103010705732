const QuestionStateTypes = Object.freeze({
  creation: 'CREATION',
  waitingApprove: 'WAITING_APPROVE',
  denied: 'DENIED',
  deleted: 'DELETED',
  approved: 'APPROVED',
  needCheck: 'NEED_CHECK',
});

const getQuestionStateTypeFromString = (value) => {
  switch (value) {
    case 'CREATION':
      return QuestionStateTypes.creation;
    case 'WAITING_APPROVE':
      return QuestionStateTypes.waitingApprove;
    case 'DENIED':
      return QuestionStateTypes.denied;
    case 'DELETED':
      return QuestionStateTypes.deleted;
    case 'APPROVED':
      return QuestionStateTypes.approved;
    case 'NEED_CHECK':
      return QuestionStateTypes.needCheck;
    default:
      return null;
  }
};

export { QuestionStateTypes, getQuestionStateTypeFromString };
