import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteConstants } from 'constants';

const RequireAuth = ({ auth, redirection, children }) => {
  const location = useLocation();

  return auth && auth.currentUser && auth.currentUser.id > 0 ? (
    <div>{React.cloneElement(children, { ...location.state })}</div>
  ) : (
    <Navigate
      to={
        redirection && redirection !== '' ? redirection : RouteConstants.SIGNIN
      }
      replace={true}
    />
  );
};

RequireAuth.defaultProps = {
  auth: null,
};

RequireAuth.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, null)(RequireAuth);
