import { PAGES } from '../constants';

// feature name
export const MY_DENIED_QUESTIONS_FEATURE = `${PAGES} [MyDeniedQuestions]`;

// action types
export const MY_DENIED_QUESTIONS_PAGE_START = `${MY_DENIED_QUESTIONS_FEATURE} PAGE_START`;
export const GET_MY_DENIED_QUESTIONS_ACTION = `${MY_DENIED_QUESTIONS_FEATURE} GET_MY_DENIED_QUESTIONS_ACTION`;

// action creators
export const myDeniedQuestionsPageStart = () => ({
  type: MY_DENIED_QUESTIONS_PAGE_START,
});

export const getMyDeniedQuestionsAction = ({ filter }) => ({
  type: GET_MY_DENIED_QUESTIONS_ACTION,
  payload: { filter },
});
