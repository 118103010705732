import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import { Grid } from '@mui/material';
import ArgonSelect from 'components/ArgonSelect';
import { connect } from 'react-redux';
import {
  getTopicsAction,
  getSubtopicsAction,
  getUsersAction,
} from 'redux/actions/pages';
import { StringHelpers } from 'helpers';
import { QuestionTypes, QuestionUsageTypes } from 'enumerations';
import { LanguageConstants } from 'constants';
import ArgonInput from 'components/ArgonInput';

const FilterCard = ({
  getTopicsAction,
  getSubtopicsAction,
  getUsersAction,
  tableFilter,
  setTableFilter,
  topics,
  subtopics,
  filterAction,
  showUserSelection,
  users,
}) => {
  useEffect(() => {
    getTopicsAction();
    getSubtopicsAction();
    if (showUserSelection === true) {
      getUsersAction();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <ArgonBox pt={3} px={3} lineHeight={1}>
        <ArgonTypography variant="h6" fontWeight="medium">
          Filtre
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox mt={1} px={3} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Konu
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={2}>
              <ArgonSelect
                placeholder="Konu seçiniz."
                isMulti
                value={topics
                  .filter((t) => tableFilter?.topics?.includes(t.id))
                  .map((t) => ({
                    label: StringHelpers.capitalizeWordsString(t.name),
                    value: t.id,
                  }))}
                onChange={(vals) => {
                  const updatedTopics = vals.map((t) => t.value);
                  let newSubtopicsValue = null;
                  if (tableFilter?.subtopics?.length > 0) {
                    newSubtopicsValue = tableFilter?.subtopics?.filter(
                      (subtopicValue) => {
                        const currentSubtopic = subtopics.find(
                          (st) => st.id === subtopicValue
                        );
                        if (currentSubtopic) {
                          return vals
                            .map((t) => t.value)
                            .includes(currentSubtopic.topicId);
                        }
                        return false;
                      }
                    );
                  }
                  setTableFilter({
                    ...tableFilter,
                    topics: updatedTopics.length > 0 ? updatedTopics : null,
                    subtopics:
                      vals?.length > 0
                        ? newSubtopicsValue
                        : tableFilter?.subtopics,
                  });
                }}
                options={topics.map((t) => ({
                  label: StringHelpers.capitalizeWordsString(t.name),
                  value: t.id,
                }))}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Alt konu
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={2}>
              <ArgonSelect
                placeholder="Alt konu seçiniz."
                isMulti
                value={subtopics
                  .filter((s) => tableFilter?.subtopics?.includes(s.id))
                  .map((s) => ({
                    label: StringHelpers.capitalizeWordsString(s.name),
                    value: s.id,
                  }))}
                onChange={(vals) => {
                  const updatedSubtopics = vals.map((s) => s.value);
                  setTableFilter({
                    ...tableFilter,
                    subtopics:
                      updatedSubtopics.length > 0 ? updatedSubtopics : null,
                  });
                }}
                options={(tableFilter?.topics === null ||
                tableFilter?.topics === undefined ||
                tableFilter?.topics?.length === 0
                  ? subtopics
                  : subtopics?.filter((s) =>
                      tableFilter?.topics?.includes(s.topicId)
                    )
                ).map((s) => ({
                  label: StringHelpers.capitalizeWordsString(s.name),
                  value: s.id,
                }))}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Soru tipi
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={2}>
              <ArgonSelect
                isClearable={true}
                placeholder="Soru tipi seçiniz."
                options={Object.values(QuestionTypes).map((qType) => {
                  return {
                    label: StringHelpers.capitalizeWordsString(
                      LanguageConstants[qType]
                    ),
                    value: qType,
                  };
                })}
                value={
                  tableFilter?.questionType
                    ? {
                        label: StringHelpers.capitalizeWordsString(
                          LanguageConstants[tableFilter?.questionType]
                        ),
                        value: tableFilter?.questionType,
                      }
                    : null
                }
                onChange={(val) => {
                  setTableFilter({
                    ...tableFilter,
                    questionType: val?.value,
                  });
                }}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Kullanım
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={2}>
              <ArgonSelect
                isClearable={true}
                placeholder="Kullanım seçiniz."
                options={Object.values(QuestionUsageTypes).map((usageType) => {
                  return {
                    label: StringHelpers.capitalizeWordsString(
                      LanguageConstants[usageType]
                    ),
                    value: usageType,
                  };
                })}
                value={
                  tableFilter?.usage
                    ? {
                        label: StringHelpers.capitalizeWordsString(
                          LanguageConstants[tableFilter?.usage]
                        ),
                        value: tableFilter?.usage,
                      }
                    : null
                }
                onChange={(val) => {
                  setTableFilter({
                    ...tableFilter,
                    usage: val?.value,
                  });
                }}
              />
            </ArgonBox>
          </Grid>

          {showUserSelection === true ? (
            <Grid item xs={12} sm={6}>
              <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Kullanıcı
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox mr={2}>
                <ArgonSelect
                  isClearable={true}
                  placeholder="Kullanıcı seçiniz."
                  options={users.map((u) => ({
                    label: u.name,
                    value: u.id,
                  }))}
                  value={
                    tableFilter?.createdBy
                      ? {
                          label: users?.find(
                            (user) => user.id === tableFilter?.createdBy
                          )?.name,
                          value: tableFilter?.createdBy,
                        }
                      : null
                  }
                  onChange={(val) => {
                    setTableFilter({
                      ...tableFilter,
                      createdBy: val?.value,
                    });
                  }}
                />
              </ArgonBox>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={6}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Soru Numarası
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={2}>
              <ArgonInput
                placeholder="Soru Numarası Giriniz.."
                type="number"
                value={tableFilter?.questionId ?? ''}
                onChange={({ currentTarget }) => {
                  setTableFilter({
                    ...tableFilter,
                    questionId:
                      currentTarget.value === '' ? null : currentTarget.value,
                  });
                }}
              />
            </ArgonBox>
          </Grid>

          <Grid item xs={12} sm={showUserSelection === true ? 12 : 6}>
            <ArgonBox display="flex" justifyContent="flex-end" mt={4}>
              <ArgonBox mr={2}>
                <ArgonButton
                  variant="contained"
                  color="light"
                  onClick={filterAction}
                >
                  Uygula
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
};

FilterCard.defaultProps = {
  tableFilter: null,
  topics: [],
  subtopics: [],
  users: [],
  showUserSelection: true,
};

FilterCard.propTypes = {
  getTopicsAction: PropTypes.func.isRequired,
  getSubtopicsAction: PropTypes.func.isRequired,
  getUsersAction: PropTypes.func.isRequired,
  tableFilter: PropTypes.objectOf(PropTypes.any),
  setTableFilter: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(PropTypes.any),
  subtopics: PropTypes.arrayOf(PropTypes.any),
  users: PropTypes.arrayOf(PropTypes.any),
  filterAction: PropTypes.func.isRequired,
  showUserSelection: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const sortedUsers = (state.data.users ?? [])
    .map((user) => ({
      ...user,
      name: StringHelpers.capitalizeWordsString(user?.name),
    }))
    .sort((a, b) => {
      if (a.isActive === b.isActive) {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      } else if (a.isActive === true) {
        return -1;
      } else {
        return 1;
      }
    });
  return {
    topics: state.data.topics,
    subtopics: state.data.subtopics,
    users: sortedUsers,
  };
};

const mapDispatchToProps = {
  getTopicsAction,
  getSubtopicsAction,
  getUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCard);
