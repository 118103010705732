import React from 'react';
import PropTypes from 'prop-types';

import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { connect } from 'react-redux';
import { insertNewQuestionAction } from 'redux/actions/pages';

const SendApproveNewQuestionButton = ({
  setValidateImageUrl,
  handleSubmit,
  values,
  errors,
  questionImageUrl,
  solutionImageUrl,
  resetSecondSection,
  insertNewQuestionAction,
}) => {
  return (
    <ArgonBox ml={2}>
      <ArgonButton
        variant="contained"
        color="warning"
        type="button"
        onClick={() => {
          setValidateImageUrl(true);

          handleSubmit();

          if (Object.keys(errors).length > 0) {
            return;
          }

          if (
            questionImageUrl === null ||
            questionImageUrl === undefined ||
            questionImageUrl === ''
          ) {
            return;
          }

          const submittedQuestion = {
            questionUrl: questionImageUrl,
            usage: values?.usage?.value ?? null,
            questionType: values?.questionType?.value ?? null,
            difficulty: values?.difficulty?.value ?? 0,
            correctAnswer: values?.correctAnswer?.value ?? null,
            videoAnswerUrl: values?.solutionVideoUrl ?? null,
            imageAnswerUrl: solutionImageUrl,
            subTopics: values?.subtopics?.map((st) => st.value),
          };

          insertNewQuestionAction({
            question: submittedQuestion,
          });

          resetSecondSection();
        }}
      >
        Onaya Gönder
      </ArgonButton>
    </ArgonBox>
  );
};

SendApproveNewQuestionButton.defaultProps = {
  values: null,
  errors: null,
  questionImageUrl: '',
  solutionImageUrl: '',
};

SendApproveNewQuestionButton.propTypes = {
  setValidateImageUrl: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  questionImageUrl: PropTypes.string,
  solutionImageUrl: PropTypes.string,
  resetSecondSection: PropTypes.func.isRequired,
  insertNewQuestionAction: PropTypes.func.isRequired,
};

const mapStateToProps = (_) => ({});

const mapDispatchToProps = {
  insertNewQuestionAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendApproveNewQuestionButton);
