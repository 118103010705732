/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { DashboardLayout, DashboardNavbar } from '../default-layout';
import Footer from 'examples/Footer';
import { connect } from 'react-redux';
import { homePageStart } from 'redux/actions/pages';

const HomePage = ({ homePageStart }) => {
  useEffect(() => {
    homePageStart();
    // eslint-disable-next-line
  }, []);

  // const { size } = typography;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Footer />
    </DashboardLayout>
  );
};

HomePage.defaultProps = {};

HomePage.propTypes = {
  homePageStart: PropTypes.func.isRequired,
};

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = {
  homePageStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
