/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Authentication layout components
import BasicLayout from '../components/BasicLayout';
import bgImage from 'assets/images/kazanirim/aydinlatma-metni.jpg';
import '../_kvkk-style.css';

function KvkkAndroid() {
  return (
    <BasicLayout
      image={bgImage}
      button={{ variant: 'gradient', color: 'dark' }}
    >
      <Card
        p={5}
        sx={{
          height: '90vh',
          overflowY: 'scroll',
        }}
      >
        <ArgonBox p={5} my={3} textAlign="center">
          <ArgonTypography
            variant="h4"
            color="dark"
            fontWeight="bold"
            sx={{ mb: 4 }}
          >
            ANDROID KVKK AYDINLATMA METNİ
          </ArgonTypography>
          <ArgonTypography variant="h5" color="text" sx={{ mb: 3 }}>
            04/01/2023 Edict Digital 6698 Sayılı Kişisel Verilerin Korunması
            Kanunu ve Android Aile Politikası Gereksinimleri Aydınlatma Metni
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            1. Veri Sorumlusunun Kimliği
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması
            Kanununun 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine
            Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ (Kanun)
            kapsamında veri sorumlusu sıfatıyla Edict Digital Bilgi
            Teknolojileri A.Ş. tarafından hazırlanmıştır.
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Şirketimiz Edict Digital Bilgi Teknolojileri A.Ş., Okuma Platformu
            yazılım uygulamasını ("Uygulama") çocukların okuma alışkanlığı
            kazanması, severek okuması için tasarlamıştır; uygulama, ebeveynlere
            ve öğretmenlere güvenilir bir içerik sağlayıcısı olarak,
            çocuklarının okuma deneyimi üzerinde tam ve doğrudan bir takip
            sağlamasını ve böylece çocukların okuma alışkanlığını keyifli bir
            deneyim ile kazanmasını amaçlamıştır. Uygulamamızı kullanmanızı
            sağlamak için herhangi bir bilgi istenmemektedir. Hizmetimizi
            kullanarak, Okuma Platformu Uygulaması aracılığıyla sizinle veya
            çocuklarınızla ilgili topladığımız bilgilerin kullanımı, depolanması
            ve açıklanmasına dair uygulamalarımızı tanımlayan bu Gizlilik
            Politikasına bağlı kalmayı kabul etmiş olursunuz. Gizliliğinizi çok
            önemsiyor ve bu nedenle, gerektiği kadar az kişisel veriyi işlemden
            geçirmeyi hedefliyoruz. "İşleme", bilgilerin toplanması, saklanması,
            silinmesi, kullanılması, birleştirilmesi ve açıklanması da dahil
            olmak üzere ancak bunlarla sınırlı olmaksızın, herhangi bir şekilde
            bilgileri kullanmak veya bilgilere dokunmak anlamına gelir. Edict
            Digital, önceden bildirmeden herhangi bir zamanda bu Gizlilik
            Politikasını değiştirme hakkını saklı tutar. Bu Gizlilik Politikası
            ile ilgili tüm değişiklikleri https://okumaplatformu.com/ adresinde
            yayınlayacağız ve güncelleme tarihini güncelleyeceğiz; bu nedenle,
            Gizlilik Politikamızın en son versiyonunu lütfen düzenli olarak
            kontrol edin. Uygulama içerisindeki gizlilik politikası bağlantısı,
            cihazınızı en son geçerli politikamıza yönlendirecektir. Bu Gizlilik
            Politikasında önemli değişiklikler yaparsak size daha dikkat çekici
            bir şekilde bildirimler gönderebilir ve sizden, ebeveynden veya
            yasal vasiden önceden izin almaya çalışabiliriz. Bu Gizlilik
            Politikası bir sözleşme değildir ve herhangi bir yasal hak veya
            yükümlülük oluşturmaz.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            2. Kişisel Verilerinizin İşlenme Amacı
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Uygulama (i) hizmeti sunmak ve geliştirmek ve (ii) suistimal
            raporlarını takip etmek ve kanun görevlileriyle işbirliği yapmak
            amaçlarıyla veri işler. Kişisel verilerinizi üçüncü taraflara
            satmaz, başka bir şekilde dağıtmaz ve açıkça yetkili olduğumuz
            durumlar dışında size hiçbir ticari öneri göndermeyiz. Hizmetimizin
            kullanımı hakkında geri bildirim edinmenin yanı sıra istatistiksel
            amaçlarla, yalnızca anonimleştirilmiş veri kullanılacaktır.
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Uygulama hangi bilgilere erişir ve bu bilgiler nasıl kullanılır?
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            sx={{
              textDecoration: 'underline',
              mb: 1,
              fontWeight: 'bold',
              fontSize: '0.8rem',
            }}
            color="text"
            textAlign="left"
          >
            KULLANICIDAN SAĞLANAN BİLGİ
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Uygulamayı indirdiğinizde ve Uygulama içerisinde kayıt olduğunuzda,
            sağladığınız bilgilere erişilir. Uygulamanın temel özelliklerini
            kullanabilmek için kayıt sürecinde uygulama üzerinden hiçbir bilgi
            talep edilmez. Uygulama tarafından sunulan özelliklerin bazılarını
            kullanmanıza izin vermek için, kayıt sırasında veya ebeveyn
            doğrulamasından sonra isteğe bağlı olarak daha fazla bilgi talep
            edilebilir. Bize kayıt olduğunuzda ve Uygulamayı kullandığınızda
            genellikle şu bilgileri sağlarsınız; (a) kullanıcı adı ve şifre (b)
            bizimle yardım için iletişime geçtiğinizde sağladığınız bilgiler.
            Ayrıca bize sağladığınız bilgiyi zaman zaman sizinle iletişim
            kurarak size önemli bilgiler vermek, gerekli bildirimleri paylaşmak
            için kullanabiliriz.
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            sx={{
              textDecoration: 'underline',
              mb: 1,
              fontWeight: 'bold',
              fontSize: '0.8rem',
            }}
            color="text"
            textAlign="left"
          >
            OTOMATİK OLARAK TOPLANAN BİLGİ
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Ek olarak, Uygulama kullandığınız mobil cihaz türü, mobil cihazınıza
            özel cihaz kimliği, mobil cihazınızın IP adresi, mobil işletim
            sisteminiz, kullandığınız mobil internet tarayıcılarının türü ve
            Uygulamayı kullanım şeklinizle ilgili bilgiler de dahil, ancak
            bunlarla sınırlı olmamak üzere belirli bilgileri otomatik olarak
            toplayabilir. Bu bilgilerin toplanma amacı yalnızca Uygulamayla
            kullanmayı seçtiğiniz hizmetleri sunmaktır. Bu bilgiler kullanıcının
            kişisel bilgileriyle bağlantılı değildir.
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            sx={{
              textDecoration: 'underline',
              mb: 1,
              fontWeight: 'bold',
              fontSize: '0.8rem',
            }}
            color="text"
            textAlign="left"
          >
            UYGULAMA, CİHAZIN KESİN GERÇEK ZAMANLI KONUM BİLGİLERİNİ TOPLUYOR
            MU?
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Bu Uygulama mobil cihazınızın konumu hakkında kesin bilgiler
            toplamaz.
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            sx={{
              textDecoration: 'underline',
              mb: 1,
              fontWeight: 'bold',
              fontSize: '0.8rem',
            }}
            color="text"
            textAlign="left"
          >
            ÜÇÜNCÜ TARAFLAR UYGULAMANIN SAHİP OLDUĞU BİLGİLERİ GÖREBİLİR VE/VEYA
            BU BİLGİLERE ERİŞEBİLİR Mİ?
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Nihai olarak Kişisel Verilerinizi size hizmet sunmamıza yardımcı
            olmaları için bünyemize kattığımız (veri girişi, veritabanı
            yönetimi, tanıtımlar, ürünler ve hizmetler uyarıları, teslimat
            hizmetleri ve üyelik doğrulama ve geçerleme dahil olmak üzere ancak
            bunlarla sınırlı olmamak üzere) hizmet sağlayıcılara açıklayabilir
            ve iletebiliriz ("Hizmet Sağlayıcıları"). Bu Hizmet Sağlayıcıları
            bize bir gizlilik yükümlülüğü ile bağlıdır ve Kişisel Verilerinizi
            doğrudan pazarlama dahil olmak üzere kendi amaçları için değil,
            yalnızca yukarıda belirtilen amaçlarla ilgili olarak kullanmalarına
            izin verilmiştir. Uygulamayı ve hizmetimizi geliştirmemize destek
            olması için anonimleştirilmiş veri normal biçimde, yalnızca
            kümelenerek; düzenli aralıklarla harici servislere iletilir.
            Bilgilerinizi yalnızca bu Gizlilik Beyanında tanımlanan yollar
            üzerinden üçüncü taraflarla paylaşacağız. Otomatik Olarak Toplanan
            Bilgileri şu koşullarda açıklayabiliriz: kanun uyarınca, mahkeme
            celbi veya benzer bir hukuki işleme uygunluk amacıyla; kendi
            haklarımızı, güvenliğinizi veya başkalarının güvenliğini korumak,
            dolandırıcılığı araştırmak veya resmi bir talebe yanıt vermek için
            açıklamanın gerekli olduğuna iyi niyetle inandığımızda; bizim
            adımıza çalışan güvenilir hizmet sağlayıcılar, onlara açıkladığımız
            bilgileri bağımsız bir şekilde kullanamazlar ve bu gizlilik
            beyanında izah edilen kurallara bağlı kalmayı kabul etmişlerdir.
            Edict Digital, varlıklarının tamamı veya bir bölümünün birleşmesi,
            satın alınması veya satışına dahil olursa, Web sitemizde bu
            bilgilerin mülkiyeti veya kullanımındaki değişikliklerin yanı sıra
            bu bilgilerle ilgili olası tercihlerinizle de ilgili, uyarıcı bir
            bildirim alırsınız.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Analiz
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Daha iyi bir kullanıcı deneyimi sunmamıza ve daha iyi ve istikrarlı
            bir uygulama geliştirmemize yardımcı olmaları için analiz
            firmalarıyla çalışmıyoruz. Hiçbir tanımlayıcı bilgi, üçüncü
            taraflarla paylaşılmaz.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Okuma Platformu Uygulamamızın Reklamları
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Üçüncü taraf reklam ağlarıyla çalışmayız. Web Sitesinde internet
            reklamcılığı yoluyla, yalnızca Okuma Platformu Uygulamamızın
            tanıtımını yapabiliriz. Böyle bir reklama tıklar ve doğrudan iTunes
            App Store veya başka bir platforma yönlendirilirseniz, telefonunuzun
            cihaz kimliği gibi tanımlama amaçlı olmayan bilgiler reklamımızın
            etkinliğini (dönüşümleri) takip edebilmemiz için reklam ağına
            gönderilebilir. Uygulama içinde toplanan bilgiyi size hedefli
            reklamlar sunmak için kullanmayız. Okuma Platformu Uygulamasının bu
            bağlantıda bulabileceğiniz ayrı ve bağımsız bir gizlilik politikası
            bulunmaktadır.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Erişim, Düzeltme, Çekilme Hakkı
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Uygulamayı kaldırarak herhangi bir bilgi toplamasını kolayca
            engelleyebilirsiniz. Uygulamayı kaldırmak için mobil cihazınızda
            mevcut olması muhtemel standart uygulama kaldırma işlemlerini, mobil
            uygulama mağazalarını ya da ağlarını kullanabilirsiniz. Lütfen
            info@edictdigital.com e-posta adresimize yazarak kişisel
            verilerinizle ilgili erişim, düzeltme, iptal ve itiraz haklarınızı
            her zaman kullanabileceğinizi dikkate alın. Çocukların verileri ile
            ilgili ebeveyn hakları için aşağıya bakın.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Veri Saklama Politikası, Bilgilerinizi Yönetme
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Kullanıcıdan Sağlanan Veriyi Uygulamayı kullandığınız sürece ve
            devamındaki makul bir zaman boyunca saklayacağız. Otomatik Olarak
            Toplanan bilgiyi 12 aya kadar saklayacak ve daha sonra toplu şekilde
            depolayacağız. Uygulama üzerinden sunduğunuz Kullanıcıdan Sağlanan
            Veriyi silmemizi isterseniz, lütfen info@edictdigital.com adresinden
            bizimle iletişim kurun; size makul bir süre içinde geri döneceğiz.
            Kullanıcıdan Sağlanan Verinin bir kısmı veya tümünün Uygulamanın
            düzgün bir şekilde çalışması için gerekli olabileceğine ve hukuken
            belirli bilgileri saklamamız gerekebileceğine dikkatinizi çekmek
            isteriz.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Çocuklar
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Web Sitesini 16 yaşın altındaki kişilerden bilerek bilgi talep etmek
            veya onlara pazarlama yapmak amacıyla kullanmıyoruz. Eğer ebeveyn
            veya vasi, kendi izni dışında çocuğunun bize bilgi sağladığının
            farkına varırsa info@edictdigital.com adresinden bizimle iletişim
            kurmalıdır. Bu gibi bilgileri makul bir süre içinde dosyalarımızdan
            sileriz. Uygulama içindeki bir özellikle bağlantılı olarak 16 yaş
            altındaki çocukların kişisel bilgilerini toplamayız. Ayrıca
            ebeveynlere çocuklarının kişisel bilgilerini inceleme, daha fazla
            bilginin toplanmasını ve kullanımını engelleme veya bu bilgilerin
            silinmesini talep etme imkanını da sunarız.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Güvenlik
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Edict Digital için bilgi güvenliği önemlidir ve bilginizin
            gizliliğini korumaya özen gösteririz. Topladığımız, işlediğimiz ve
            sakladığımız bilgileri korumak için fiziksel, elektronik, idari ve
            yöntemsel emniyet tedbirleri tesis ettik. Bilgilerinize yetkisiz
            erişimin, açıklamanın ve uygun olmayan şekilde işlenmelerinin
            engellenmesi ve verinin doğruluğunun ve bütünlüğünün korunması için
            bu emniyet tedbirleri düzenli olarak gözden geçirilir. Örneğin, bu
            bilgiye erişimi yetkili çalışanlar ve web sitemizi çalıştırmak,
            geliştirmek veya iyileştirmek için bu bilgiye ihtiyacı olan
            yüklenicilerle sınırlarız. İşlediğimiz ve sakladığımız bilgiler için
            makul bir güvenlik sağlamaya gayret etmemize rağmen, hiçbir güvenlik
            sisteminin tüm potansiyel güvenlik ihlallerini engelleyemeyeceğini
            lütfen göz önünde bulundurun. Teknik ve operasyonel nedenlerle
            kişisel veriler Avrupa Birliği dışında, Avrupa veri koruma
            kurallarının uygulanmadığı noktalarda konumlanan sunucular üzerinden
            aktarılabilmektedir. AB dışındaki gizlilik düzenlemeleri, aynı
            yüksek seviyede koruma sağlayamayabilir.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            Değişiklikler
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Bu Gizlilik Politikası herhangi bir nedenle zaman zaman
            güncellenebilir. Yeni Güvenlik Politikasını
            https://okumaplatformu.com/ web sitemizde yayınlayarak Güvenlik
            Politikamızdaki herhangi bir değişikliği size bildiririz ve yukarıda
            gösterilen tarihi güncelleriz. Değişiklik olması durumunda
            kullanımınızın devam etmesi tüm değişiklikleri onayladığınız
            şeklinde yorumlanacağından, değişiklikler için bu Gizlilik
            Politikasını düzenli olarak kontrol etmeniz tavsiye edilir. Bu
            Gizlilik Politikasında önemli değişiklikler yaparsak, size daha
            önemli bildirimler gönderebilir ve sizden, ebeveynden veya yasal
            vasiden önceden izin almaya çalışabiliriz.
          </ArgonTypography>
          <ArgonTypography
            variant="h6"
            sx={{ textDecoration: 'underline', mb: 1 }}
            color="text"
            textAlign="left"
          >
            İletişim
          </ArgonTypography>
          <ArgonTypography
            variant="body2"
            color="text"
            textAlign="left"
            sx={{ mb: 2 }}
          >
            Haklarınıza yönelik başvurularınızı edictdigital@gmail.com adresine
            iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en
            geç otuz gün içinde ücretsiz olarak başvurularınız
            sonuçlandırılacaktır.
          </ArgonTypography>
        </ArgonBox>
      </Card>
    </BasicLayout>
  );
}

export default KvkkAndroid;
