/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import {
  QuestionUsageTypes,
  QuestionTypes,
  QuestionDifficultyTypes,
} from 'enumerations';
import { LanguageConstants } from 'constants';
import { StringHelpers } from 'helpers';

import Loading from 'components/Loading';
import { GET_TOPICS_ACTION } from 'redux/actions/pages';

import FormField from '../FormField';
import { connect } from 'react-redux';

function QuestionInfo({ formData, topics, subtopics }) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    // handleBlur,
    // handleChange,
  } = formData;

  const {
    usage: { errorMsg: _usage, ...usageRest },
    topics: { errorMsg: _topics, ...topicsRest },
    subtopics: { errorMsg: _subtopics, ...subtopicsRest },
    questionType: { errorMsg: _questionType, ...questionTypeRest },
    difficulty: { errorMsg: _difficulty, ...difficultyRest },
  } = formField;

  const {
    usage: usageV,
    topics: topicsV,
    subtopics: subtopicsV,
    questionType: questionTypeV,
    difficulty: difficultyV,
  } = values;

  useEffect(() => {
    if (subtopicsV && subtopicsV.length > 0) {
      const newSubtopicsValue = subtopicsV.filter((subtopicValue) => {
        const currentSubtopic = subtopics.find(
          (st) => st.id === subtopicValue.value
        );
        if (currentSubtopic) {
          return topicsV.map((t) => t.value).includes(currentSubtopic.topicId);
        }
        return false;
      });

      if (newSubtopicsValue) {
        setFieldValue(subtopicsRest.name, newSubtopicsValue);
      } else {
        setFieldValue(subtopicsRest.name, []);
      }
    }
    // eslint-disable-next-line
  }, [topicsV, subtopics]);

  return (
    <ArgonBox mb={3}>
      <Card sx={{ overflow: 'visible' }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Soru Genel Özellikleri</ArgonTypography>
          <ArgonBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div style={{ position: 'relative' }}>
                  <Loading identifier={GET_TOPICS_ACTION} />
                  <FormField
                    {...topicsRest}
                    options={topics.map((t) => ({
                      label:
                        t.code + StringHelpers.capitalizeWordsString(t.name),
                      value: t.id,
                    }))}
                    value={topicsV}
                    error={
                      errors.topics?.length > 0 &&
                      touched.topics !== undefined &&
                      touched.topics !== false
                    }
                    success={
                      topicsV &&
                      !errors.topics &&
                      touched.topics !== undefined &&
                      touched.topics !== false
                    }
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ position: 'relative' }}>
                  <Loading identifier={GET_TOPICS_ACTION} />
                  <FormField
                    {...subtopicsRest}
                    options={subtopics
                      ?.filter((s) =>
                        topicsV?.map((t) => t.value)?.includes(s.topicId)
                      )
                      ?.map((s) => ({
                        label: StringHelpers.capitalizeWordsString(s.name),
                        value: s.id,
                      }))}
                    value={subtopicsV}
                    error={
                      errors.subtopics?.length > 0 &&
                      touched.subtopics !== undefined &&
                      touched.subtopics !== false
                    }
                    success={
                      subtopicsV &&
                      !errors.subtopics &&
                      touched.subtopics !== undefined &&
                      touched.subtopics !== false
                    }
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </Grid>
            </Grid>
          </ArgonBox>
          <ArgonBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  {...questionTypeRest}
                  options={Object.values(QuestionTypes).map((qType) => {
                    return {
                      label: StringHelpers.capitalizeWordsString(
                        LanguageConstants[qType]
                      ),
                      value: qType,
                    };
                  })}
                  value={questionTypeV}
                  error={
                    errors.questionType?.length > 0 &&
                    !(touched.questionType !== true)
                  }
                  success={questionTypeV && !errors.questionType}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  {...difficultyRest}
                  options={Object.values(QuestionDifficultyTypes).map(
                    (difficultyType) => {
                      return {
                        label: difficultyType,
                        value: difficultyType,
                      };
                    }
                  )}
                  value={difficultyV}
                  error={
                    errors.difficulty?.length > 0 &&
                    !(touched.difficulty !== true)
                  }
                  success={difficultyV && !errors.difficulty}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
            </Grid>
          </ArgonBox>
          <ArgonBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  {...usageRest}
                  options={Object.values(QuestionUsageTypes).map(
                    (usageType) => {
                      return {
                        label: StringHelpers.capitalizeWordsString(
                          LanguageConstants[usageType]
                        ),
                        value: usageType,
                      };
                    }
                  )}
                  value={usageV}
                  error={errors.usage?.length > 0 && !(touched.usage !== true)}
                  success={usageV && !errors.usage}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </Grid>
            </Grid>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </ArgonBox>
  );
}

QuestionInfo.defaultProps = {
  topics: [],
  subtopics: [],
};

QuestionInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  topics: PropTypes.arrayOf(PropTypes.any),
  subtopics: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  topics: state.data.topics,
  subtopics: state.data.subtopics,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionInfo);
